import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    registerables 
} from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    ...registerables
)

const BarChart = (props) => {
    const {data, title} = props
    const allDataValues = data.datasets.flatMap(dataset => dataset.data);
    const maxValue = Math.max(...allDataValues);

    let stepSize, max;
    if (maxValue < 100) {
        stepSize = 10;
        max = 100
    } else if (maxValue < 1000) {
        stepSize = 1000; 
        max = 10000
    } else if (maxValue < 10000) {
        stepSize = 10000; 
        max = 100000
    } else if (maxValue < 100000) { //100k
        stepSize = 10000; //10k
        max = 100000 //100k
    } else if (maxValue < 1000000) { //1M
        stepSize = 100000; //l0k
        max = 1000000 //1M
    }else if (maxValue < 10000000) { //10M
        stepSize = 1000000; //lM
        max = 10000000//10M
    }

    const getUnit = (maxValue) => {
        if (maxValue >= 1000000) {
          return '(Millions)';
        }
        if (maxValue >= 1000) {
          return '(Thousands)';
        }
        if (maxValue >= 100) {
            return '(Hundreds)';
        }
        // if (maxValue >= 0) {
        //     return 'Tens';
        // }
        return '';
    };

    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                bar: {
                    barThickness: 30,
                },
                display: true,
                color: '#1e1e1e', // Label text color
                align: 'top', // Position of the label relative to the point
                formatter: (value) => {
                  if (value !== 0) {
                    if (value >= 1000000) {
                        return (value / 1000000).toFixed(2) + 'M'; // Convert to "M" notation
                    }
                    if (value >= 1000) {
                        return (value / 1000).toFixed(2) + 'k'; // Convert to "k" notation
                    }
                    return value; // Display only non-zero values
                  }
                  return null; // Hide labels for zero values
                },
            },
            legend: {
                position: 'top',
                labels: {
                    color: '#1e1e1e',
                }
            },
            title: {
                display: true,
                text: title,
                color: '#1e1e1e'
            },
        },
        maintainAspectRatio: false,
        // datalabels: {
        //     anchor: 'end',
        //     align: 'top',
        //     color: 'black',
        //     font: {
        //       weight: 'bold',
        //     },
        // },
        // indexAxis: 'y',
        // responsiveAnimationDuration: 0,
        // scales: {
        //     x: {
        //         display: true,
        //         title: {
        //             display: true,
        //             text: 'Month',
        //         },
        //     },
        //     y: {
        //         display: true,
        //         title: {
        //             display: true,
        //             text: 'Value',
        //         },
        //         // beginAtZero: true,
        //     },
        // },
        layout: {
            padding: 10
        },
        animation: true,
        animationDuration: 2000,
        animationEasing: 'easeInCubic',
        scales: {
            x: {
              grid: {
                display: false, // Disable grid lines for the x-axis
              },
              ticks: {
                color: '#1e1e1e',
              }
            },
            y: {
                title: {
                    display: true,
                    text: `${getUnit(maxValue)}`,
                },
                grid: {
                    display: false,
                    color: '#d9d9d9',
                },
                ticks: {
                    color: '#1e1e1e',
                    stepSize: stepSize,
                    callback: (value) => {
                        if (value !== 0) {
                          if (value >= 1000000) {
                              return (value / 1000000); // Convert to "M" notation
                          }
                          if (value >= 1000) {
                              return (value / 1000); // Convert to "k" notation
                          }
                          return value; // Display only non-zero values
                        }
                        return null; // Hide labels for zero values
                    },
                },
                max: max,
            }
        },
    }    

    return (
        <Bar options={options} data={data} />
    )
}

export default BarChart