import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import { ReactProps } from "../interfaces/index.interface"
import { useDispatch, useSelector } from "react-redux";
import { loginTepngUser, makeGetRequest, } from "../request";
import { useEffect } from 'react';
import { setUnreadCount } from "store/unreadCount";
import { setTepngUser } from "../store/tepngUser";
import { setRoles } from "../store/roles";
import { setPermissions } from "../store/permissions";

const Layout  = ({children, title}: ReactProps) => {
    const dispatch = useDispatch() 
    const user:any = useSelector((state: any) => state.tepngUser.value);
    const role = window.location.pathname.split('/')[1]
    
    const getUnreadNotificationsCount = () => {
        let apiCallParameters = {
            endpoint: "",
            receiver: ""
        }
        if(role === "supplier") apiCallParameters.endpoint = "getUnreadMaterialReadinessDocumentNotifications"
        else if(["transitofficer", "freightforwarder", "localclearingagent"].includes(role)) apiCallParameters.endpoint = "getUnreadShippingDocumentNotifications"

        if(role === "transitofficer") apiCallParameters.receiver = "TransitOfficer"
        else apiCallParameters.receiver = user?.id

        var request: Record<string, any> = {
            what: apiCallParameters.endpoint,
            params: {
                Receiver: apiCallParameters.receiver,
            }
        };
        
        if(Object.values(apiCallParameters).every(value => value !== "")) {
            makeGetRequest(request)
            .then((response: any) => {
                const res = response.data.data
                // setUnreadCount(3)
                dispatch(setUnreadCount(res.length))

            })
        }
    }    

    const redirectUser = (roles: any) => {
        const currentPagePath = window.location.pathname.split("/")[1]
        const fRoles = roles.map((role: string) => role.toLowerCase().replaceAll(" ", ""))

        // Validate if user assigned roles is equal to the path tried to access
        if(!fRoles.includes(currentPagePath)) {
            window.location.replace(window.location.origin + "/unauthorized")    
        }

        // Redirect user after login
        const redirectURL = sessionStorage.getItem("redirectURL")                
        if(redirectURL !== null) window.location.replace(redirectURL)
        sessionStorage.removeItem("redirectURL")
    }

    useEffect(() => {        
        getUnreadNotificationsCount(); //eslint-disable-next-line
    }, [window.location, user]);

    useEffect(() => {
        if(!user){
            loginTepngUser().then((res: any) => {
                if(res) {
                    dispatch(setTepngUser(res.userDetails))
                    dispatch(setRoles(res.roles))
                    dispatch(setPermissions(res.permissions))
                    redirectUser(res.roles)
                }             
            }) 
        }
        // if(!accessToken || new Date(accessToken?.expiration) < new Date()) {
        //     RefreshToken(dispatch)
        //     ?.then((res:any) => {
        //         const currentPagePath = window.location.pathname.split("/")[1]
        //         const fRoles = res.roles.map((role: string) => role.toLowerCase().replaceAll(" ", ""))

        //         // Validate if user assigned roles is equal to the path tried to access
        //         if(!fRoles.includes(currentPagePath)){
        //             sessionStorage.setItem("token", res.accessToken?.token)
        //             window.location.replace(window.location.origin + "/unauthorized")                        
        //         }

        //         //Redirect user after login
        //         const redirectURL = sessionStorage.getItem("redirectURL")                
        //         if(redirectURL !== null) window.location.replace(redirectURL)
        //         // else navigate(`/${res.roles[0].toLowerCase().replaceAll(" ","")}/dashboard`)
        //         sessionStorage.removeItem("redirectURL")
        //     })
        //     .catch((error:any) => console.log("error: ",error));
        // }
        //eslint-disable-next-line
    }, [])
    
    return (
        <>
            {user && <>
                <Header page={title} />
                <Sidebar page={title} />
                <div>{ children }</div>
            </>}
        </>
    )
}

export default Layout