import { Months } from "helpers";


export const graphOfNumberOfAssignedAndDeliveredShipment = (result: { assigned: { month: number; count: number }[]; delivered: { month: number; count: number }[];}) => {
    const assignedData = new Array(12).fill(0);
    const deliveredData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.assigned?.forEach((item: { month: number; count: number }) => {
        assignedData[item.month - 1] = item.count;
    });

    result?.delivered?.forEach((item: { month: number; count: number })  => {
        deliveredData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Assigned',
                fill: true,
                data: assignedData,
                borderColor: 'rgb(75, 130, 192)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(75, 130, 192, 0.2)'
            },
            {
                label: 'Delivered',
                fill: true,
                data: deliveredData,
                borderColor: 'rgba(1, 234, 36)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(9, 234, 1, 0.2)'
            },
        ]
    };
}