// import BarChart from "components/charts/VBar";
// import PieChart from "components/charts/Pie";
// import LineChart from "components/charts/Line";
import Layout from "../../Layout"
import { customStyles, formatWithCommas, getDataInceptionYearToCurrentYear, getSelectedMonthIndex, Months } from "helpers";
import { useDashboardData } from "./Requests";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import loading from "../../../assets/images/loading.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRange } from "interfaces/index.interface";
import { graphOfNumberOfAssignedAndClosedMaterialReadinessDocument, graphOfNumberOfConsolidatedAndNotConsolidated } from "./ChartData";
import BarChart from "components/charts/VBar";
import MiniDataCard from "components/MiniDataCard";

const Dashboard =  () => {
    const currentYear = new Date().getFullYear();
    const [isLoading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const [startDate, setStartDate] = useState<string>(`${currentYear}-01-01`); // initial value before any selection
    const [endDate, setEndDate] = useState<string>(`${currentYear}-12-31`);
    const {TotalShippedDocuments, 
        TotalConsolidatedDocuments, 
        TotalClosedMaterialReadinessDocuments, 
        TotalAssignedMaterialReadinessDocuments, 
        NumberOfAssignedAndClosedMaterialReadinessDocument,
        NumberOfConsolidatedAndNotConsolidated,
        PercentageChangeOfTotalAssignedMaterialReadinessDocuments,
        PercentageChangeOfTotalClosedMaterialReadinessDocuments,
        PercentageChangeOfTotalConsolidatedDocuments,
        PercentageChangeOfTotalShippedDocuments
    } = useDashboardData();
    const [dashboardData, setDashboardData] = useState({
        totalAssignedMRDs: 0,
        totalClosedMRDs: 0,
        totalConsolidatedDocs: 0,
        totalShippedDocs: 0,
        percentageChangeOfTotalAssignedMaterialReadinessDocuments: 0,
        percentageChangeOfTotalClosedMaterialReadinessDocuments: 0,
        percentageChangeOfTotalConsolidatedDocuments: 0,
        percentageChangeOfTotalShippedDocuments: 0,
        graphofNumberOfAssignedAndClosedMaterialReadinessDocument: [] as any,
        graphofNumberOfConsolidatedAndNotConsolidated: [] as any,
    });


    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        Promise.all([
            TotalAssignedMaterialReadinessDocuments(startDate, endDate),
            TotalClosedMaterialReadinessDocuments(startDate, endDate),
            TotalConsolidatedDocuments(startDate, endDate),
            TotalShippedDocuments(startDate, endDate),
            NumberOfAssignedAndClosedMaterialReadinessDocument(getSelectedMonthIndex(selectedMonth), selectedYear),
            NumberOfConsolidatedAndNotConsolidated(getSelectedMonthIndex(selectedMonth), selectedYear),
            PercentageChangeOfTotalAssignedMaterialReadinessDocuments(startDate, endDate),
            PercentageChangeOfTotalClosedMaterialReadinessDocuments(startDate, endDate),
            PercentageChangeOfTotalConsolidatedDocuments(startDate, endDate),
            PercentageChangeOfTotalShippedDocuments(startDate, endDate),
        ])
            .then(([totalAssignedMRDs, 
                totalClosedMRDs, 
                totalConsolidatedDocs, 
                totalShippedDocs, 
                graphofNumberOfAssignedAndClosedMaterialReadinessDocument, 
                graphofNumberOfConsolidatedAndNotConsolidated,
                percentageChangeOfTotalAssignedMaterialReadinessDocuments,
                percentageChangeOfTotalClosedMaterialReadinessDocuments,
                percentageChangeOfTotalConsolidatedDocuments,
                percentageChangeOfTotalShippedDocuments
            ]) => {
                setDashboardData({
                    totalAssignedMRDs: totalAssignedMRDs,
                    totalClosedMRDs: totalClosedMRDs,
                    totalConsolidatedDocs: totalConsolidatedDocs,
                    totalShippedDocs: totalShippedDocs,
                    graphofNumberOfAssignedAndClosedMaterialReadinessDocument: graphofNumberOfAssignedAndClosedMaterialReadinessDocument,
                    graphofNumberOfConsolidatedAndNotConsolidated: graphofNumberOfConsolidatedAndNotConsolidated,
                    percentageChangeOfTotalAssignedMaterialReadinessDocuments: percentageChangeOfTotalAssignedMaterialReadinessDocuments,
                    percentageChangeOfTotalClosedMaterialReadinessDocuments: percentageChangeOfTotalClosedMaterialReadinessDocuments,
                    percentageChangeOfTotalConsolidatedDocuments: percentageChangeOfTotalConsolidatedDocuments,
                    percentageChangeOfTotalShippedDocuments: percentageChangeOfTotalShippedDocuments
                });
            })
            .catch((error) => {
                console.error("Error fetching dashboard data:", error);
                toast.error("Error fetching dashboard data");
            })
            .finally(() => {
                setLoading(false); // Set loading to false when done
            });
        //eslint-disable-next-line
    }, [startDate, endDate, selectedMonth, selectedYear]);

    const updateDateRange = (selectedMonth: string, selectedYear: number): DateRange => {
        let startDate: string;
        let endDate: string;
    
        if (selectedMonth && selectedYear) {
            // If both month and year are selected
            const monthIndex = Months.indexOf(selectedMonth) + 1; // Get the month index (1-12)
            const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`; // Ensure two digits
            startDate = `${selectedYear}-${month}-01`;
            
            // Calculate the last day of the selected month
            const lastDayOfMonth = new Date(selectedYear, monthIndex, 0).getDate();
            endDate = `${selectedYear}-${month}-${lastDayOfMonth}`;
        } else if (selectedYear) {
            // If only year is selected
            startDate = `${selectedYear}-01-01`;
            endDate = `${selectedYear}-12-31`;
        } else {
            // If neither month nor year is selected, return an empty range or handle accordingly
            startDate = '';
            endDate = '';
        }
    
        return { startDate, endDate };
    }

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedMonth = event.target.value;
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        setSelectedMonth(selectedMonth);
        setStartDate(startDate);
        setEndDate(endDate);
    };
    
    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedYear = parseInt(event.target.value, 10);
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        if(!selectedYear) setSelectedMonth("")
        setSelectedYear(selectedYear);
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const linedata = graphOfNumberOfAssignedAndClosedMaterialReadinessDocument(dashboardData.graphofNumberOfAssignedAndClosedMaterialReadinessDocument);
    const bardata = graphOfNumberOfConsolidatedAndNotConsolidated(dashboardData.graphofNumberOfConsolidatedAndNotConsolidated);

    const page = "Dashboard Analytics"    
    return (
        <Layout title={page}>
            <div className="container">
                <div className="main">
                    <h3 className="page_title">{  }</h3>     
                                        
                    <div className="grid-area-2">
                        <section className="header">
                            <small className="f-12 text-grey">Month</small>
                            <select value={selectedMonth} onChange={handleMonthChange} disabled={!selectedYear}>
                                <option key="0" value="">All</option>
                                {
                                    Months.map((month: string, index: number) => {
                                        return (
                                            <option key={index}>{month}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className="f-12 text-grey ml-2">Year</small>
                            <select value={selectedYear} onChange={handleYearChange}>
                                <option key="0" value="">All</option>
                                {
                                    getDataInceptionYearToCurrentYear().map((year: number, index: number) => {
                                        return (
                                            <option key={index+1}>{year}</option>
                                        )
                                    })
                                }
                            </select>
                        </section>
                        <div className="card1">
                            <div className="dashboard-card">
                                <div>
                                    <div className="blue">
                                        <span className="material-symbols-rounded">note_stack</span>
                                        <p>Assigned Material Readiness Document </p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalAssignedMRDs ?? 0) }</p>
                                <MiniDataCard value={dashboardData.percentageChangeOfTotalAssignedMaterialReadinessDocuments} />
                            </div>                            
                        </div>
                        <div className="card2">
                            <div className="dashboard-card">
                                <div>
                                    <div className="green">
                                        <span className="material-symbols-rounded">list_alt_check</span>
                                        <p>POC Generated MRDs</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalClosedMRDs ?? 0) }</p>
                                <MiniDataCard value={dashboardData.percentageChangeOfTotalClosedMaterialReadinessDocuments} />
                            </div>
                        </div>
                        <div className="card3">
                            <div className="dashboard-card">
                                <div>
                                    <div className="yellow">
                                        <span className="material-symbols-rounded">article</span>
                                        <p>Total Consolidated MRDs</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalConsolidatedDocs ?? 0) }</p>
                                <MiniDataCard value={dashboardData.percentageChangeOfTotalConsolidatedDocuments} />
                            </div>
                        </div>
                        <div className="card4">
                            <div className="dashboard-card">
                                <div>
                                    <div className="purple">
                                        <span className="material-symbols-rounded">sailing</span>
                                        <p>Total Shipped Document</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalShippedDocs ?? 0) }</p>
                                <MiniDataCard value={dashboardData.percentageChangeOfTotalShippedDocuments} />
                            </div>
                        </div>
                        
                        <div className="box1">
                            <BarChart data={linedata} title="Monthly Assigned and POC Generated MRD" />
                        </div>
                        <div className="box2">
                            <BarChart data={bardata} title="Monthly Consolidated and Not Consolidated MRDs" />                            
                        </div>
                    </div> 
                </div>
                <ToastContainer />
                <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                    <div className="loader">
                        <img src={loading} alt="loading" />
                        <p>Loading data...</p>
                    </div>
                </Modal> 
            </div>                            
        </Layout>
    )
}

export default Dashboard