import MiniDataCard from "components/MiniDataCard";
import Layout from "../../Layout"
import { customStyles, formatWithCommas, getDataInceptionYearToCurrentYear, Months, getSelectedMonthIndex } from "helpers";
import { useDashboardData } from "./Requests";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import loading from "../../../assets/images/loading.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRange } from "interfaces/index.interface";
import BarChart from "components/charts/VBar";
import { graphOfNumberOfCompletedAndNotCompletedMRD, graphOfNumberOfIssuedPickedAndOutstandingMRD } from "./ChartData";
import { useSelector } from "react-redux";

const Dashboard =  () => {
    const user:any = useSelector((state: any) => state.tepngUser.value);
    const currentYear = new Date().getFullYear();
    const [isLoading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const [startDate, setStartDate] = useState<string>(`${currentYear}-01-01`); // initial value before any selection
    const [endDate, setEndDate] = useState<string>(`${currentYear}-12-31`);
    const {TotalInactivePurchaseOrders, 
        PercentageChangeOfAllActivePurchaseOrders,
        TotalIssuedMaterialReadinessDocument, 
        PercentageChangeOfAllIssuedMaterialReadinessDocument,
        TotalMaterialReadinessDocumentWithPOC, 
        PercentageChangeOfTotalMaterialReadinessDocumentWithPOC,
        TotalPendingMaterialReadinessDocument,
        PercentageChangeOfAllPendingMaterialReadinessDocument,
        NumberOfIssuedPickedAndOutstandingMRD,
        NumberOfCompletedAndNotCompletedMRD,
    } = useDashboardData();
    const [dashboardData, setDashboardData] = useState({
        totalInactivePOs: 0,
        totalIssuedMRDs: 0,
        totalPendingMRDs: 0,
        totalMRDsWithPOC: 0,
        percentageChangeOfAllActivePurchaseOrders: 0,
        percentageChangeOfAllIssuedMaterialReadinessDocument: 0,
        percentageChangeOfAllPendingMaterialReadinessDocument: 0,
        percentageChangeOfTotalMaterialReadinessDocumentWithPOC: 0,
        graphOfNumberOfIssuedPickedAndOutstandingMRD: [] as any,
        graphOfNumberOfCompletedAndNotCompletedMRD: [] as any
    });    

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        
        Promise.all([
            TotalInactivePurchaseOrders(startDate, endDate),
            TotalIssuedMaterialReadinessDocument(startDate, endDate),
            TotalPendingMaterialReadinessDocument(startDate, endDate),
            TotalMaterialReadinessDocumentWithPOC(startDate, endDate),              
            NumberOfIssuedPickedAndOutstandingMRD(getSelectedMonthIndex(selectedMonth), selectedYear),
            NumberOfCompletedAndNotCompletedMRD(getSelectedMonthIndex(selectedMonth), selectedYear),
            PercentageChangeOfAllActivePurchaseOrders(startDate, endDate),
            PercentageChangeOfAllIssuedMaterialReadinessDocument(startDate, endDate),
            PercentageChangeOfAllPendingMaterialReadinessDocument(startDate, endDate),
            PercentageChangeOfTotalMaterialReadinessDocumentWithPOC(startDate, endDate),
        ])
            .then(([totalInactivePOs, 
                totalIssuedMRDs, 
                totalPendingMRDs, 
                totalMRDsWithPOC, 
                graphOfNumberOfIssuedPickedAndOutstandingMRD, 
                graphOfNumberOfCompletedAndNotCompletedMRD,
                percentageChangeOfAllActivePOs,
                percentageChangeOfAllIssuedMaterialReadinessDocument,
                percentageChangeOfAllPendingMaterialReadinessDocument,
                percentageChangeOfTotalMaterialReadinessDocumentWithPOC
            ]) => {
                setDashboardData({
                    totalInactivePOs: totalInactivePOs,
                    totalIssuedMRDs: totalIssuedMRDs,
                    totalPendingMRDs: totalPendingMRDs,
                    totalMRDsWithPOC: totalMRDsWithPOC,
                    graphOfNumberOfIssuedPickedAndOutstandingMRD: graphOfNumberOfIssuedPickedAndOutstandingMRD,
                    graphOfNumberOfCompletedAndNotCompletedMRD: graphOfNumberOfCompletedAndNotCompletedMRD,
                    percentageChangeOfAllActivePurchaseOrders: percentageChangeOfAllActivePOs,
                    percentageChangeOfAllIssuedMaterialReadinessDocument: percentageChangeOfAllIssuedMaterialReadinessDocument,
                    percentageChangeOfAllPendingMaterialReadinessDocument: percentageChangeOfAllPendingMaterialReadinessDocument,
                    percentageChangeOfTotalMaterialReadinessDocumentWithPOC: percentageChangeOfTotalMaterialReadinessDocumentWithPOC
                });
            })
            .catch((error) => {
                // console.error("Error fetching dashboard data:", error);
                toast.error("Error fetching dashboard data");
            })
            .finally(() => {
                setLoading(false); // Set loading to false when done
            });
        //eslint-disable-next-line
    }, [user, startDate, endDate, selectedMonth, selectedYear]);

    const updateDateRange = (selectedMonth: string, selectedYear: number): DateRange => {
        let startDate: string;
        let endDate: string;
    
        if (selectedMonth && selectedYear) {
            // If both month and year are selected
            const monthIndex = Months.indexOf(selectedMonth) + 1; // Get the month index (1-12)
            const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`; // Ensure two digits
            startDate = `${selectedYear}-${month}-01`;
            
            // Calculate the last day of the selected month
            const lastDayOfMonth = new Date(selectedYear, monthIndex, 0).getDate();
            endDate = `${selectedYear}-${month}-${lastDayOfMonth}`;
        } else if (selectedYear) {
            // If only year is selected
            startDate = `${selectedYear}-01-01`;
            endDate = `${selectedYear}-12-31`;
        } else {
            // If neither month nor year is selected, return an empty range or handle accordingly
            startDate = '';
            endDate = '';
        }
    
        return { startDate, endDate };
    }

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedMonth = event.target.value;
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        setSelectedMonth(selectedMonth);
        setStartDate(startDate);
        setEndDate(endDate);
    };
    
    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedYear = parseInt(event.target.value, 10);
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        if(!selectedYear) setSelectedMonth("")
        setSelectedYear(selectedYear);
        setStartDate(startDate);
        setEndDate(endDate);
    };  

    const bardata2 = graphOfNumberOfIssuedPickedAndOutstandingMRD(dashboardData?.graphOfNumberOfIssuedPickedAndOutstandingMRD)
    const bardata = graphOfNumberOfCompletedAndNotCompletedMRD(dashboardData?.graphOfNumberOfCompletedAndNotCompletedMRD)

    const page = "Dashboard Analytics"    
    return (
        <Layout title={page}>
            <div className="container">
                <div className="main">
                    <h3 className="page_title">{  }</h3>     
                                        
                    <div className="grid-area-2">
                        <section className="header">
                            <small className="f-12 text-grey">Month</small>
                            <select value={selectedMonth} onChange={handleMonthChange} disabled={!selectedYear}>
                                <option key="0" value="">All</option>
                                {
                                    Months.map((month: string, index: number) => {
                                        return (
                                            <option key={index}>{month}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className="f-12 text-grey ml-2">Year</small>
                            <select value={selectedYear} onChange={handleYearChange}>
                                <option key="0" value="">All</option>
                                {
                                    getDataInceptionYearToCurrentYear().map((year: number, index: number) => {
                                        return (
                                            <option key={index+1}>{year}</option>
                                        )
                                    })
                                }
                            </select>
                        </section>
                        <div className="card1">
                            <div className="dashboard-card">
                                <div>
                                    <div className="green">
                                        <span className="material-symbols-rounded">list_alt</span>
                                        <p>Inactive Purchase Orders </p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalInactivePOs ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfAllActivePurchaseOrders} />
                            </div>                            
                        </div>
                        <div className="card2">
                            <div className="dashboard-card">
                                <div>
                                    <div className="yellow">
                                        <span className="material-symbols-rounded">note_stack</span>
                                        <p>Issued Material Readiness Documents</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalIssuedMRDs ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfAllIssuedMaterialReadinessDocument} />
                            </div>
                        </div>
                        <div className="card3">
                            <div className="dashboard-card">
                                <div>
                                    <div className="red">
                                        <span className="material-symbols-rounded">other_admission</span>
                                        <p>Rejected Material Readiness Documents</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalPendingMRDs ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfAllPendingMaterialReadinessDocument} />
                            </div>
                        </div>
                        <div className="card4">
                            <div className="dashboard-card">
                                <div>
                                    <div className="blue">
                                        <span className="material-symbols-rounded">box</span>
                                        <p>Material Readiness Documents With POC</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalMRDsWithPOC ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalMaterialReadinessDocumentWithPOC} />
                            </div>
                        </div>
                        
                        <div className="box1">
                            <BarChart data={bardata} title="Monthly Purchase Orders" />
                        </div>
                        <div className="box2">
                            <BarChart data={bardata2} title="Monthly Issued, Rejected and Picked MRD" />
                        </div>
                    </div> 
                </div>
                <ToastContainer />
                <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                    <div className="loader">
                        <img src={loading} alt="loading" />
                        <p>Loading data...</p>
                    </div>
                </Modal> 
            </div>                            
        </Layout>
    )
}

export default Dashboard