import { MsalConfig } from "interfaces/index.interface";

export const msalConfig: MsalConfig = {
    
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID ?? "",
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID || "common"}`,
      redirectUri: process.env.REACT_APP_REDIRECT_URI ?? "",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
};
  
export const loginRequest = {
    scopes: ["user.read"], // Define the scopes your app needs
};