import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {  ThemeProvider, createTheme } from '@mui/material/styles';
import './index.css'; // Your custom CSS
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { makePostRequest } from 'request';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setAccessToken } from "store/accessToken";


const msalInstance = new PublicClientApplication(msalConfig);// Initialize MSAL
const GetDispatch = () => { return useDispatch() }
const GetNavigate = () => { return useNavigate() }

// Optional: Add event callbacks for logging or debugging
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    const payload = event.payload ?? {};
    
    if (payload && 'accessToken' in payload) {
      // call API to send AD accessToken to backend
      var request:Record<string, any> = {
          what: "LoginThirdPartyWithMicrosoftAD",
          accessToken: payload.accessToken,
          data: {
            userId: payload.account?.idTokenClaims?.oid,
            email: payload.account?.username
          }
      }; 
      makePostRequest(request)
          .then((res:any) => {  
            try {
              const response = res.data.data                
              const redirectURL = sessionStorage.getItem("redirectURL")
              if(redirectURL !== null) window.location.replace(redirectURL)//Redirect user after login
              else {
                window.location.reload()
                const navigate = GetNavigate()
                navigate(`/${response.roles[0].toLowerCase().replaceAll(" ","")}/dashboard`)
              }
              sessionStorage.removeItem("redirectURL") 
              
              const dispatch = GetDispatch()
              dispatch(setAccessToken(response.accessToken)) // set access token             
               
            } catch { return null }
          })
          .catch((error:any) => console.log(error));
    }
  }
});
// the theme/ css styling for the react stepper material ui component
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '10px !important',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-root': {
            padding: '8px !important',
            borderRadius: '6px',
            '&:focus': {
              outline: 'none !important',
            },
          },
          '.MuiAutocomplete-input': {
            border: 'none !important',
            margin: '0 !important',
            fontSize: '11px !important',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed': {
            color: '#27A445 !important',
          },
          '&.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active': {
            color: '#175FDC !important',
          },
          '&.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root': {
            height: '18px !important',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            fontSize: '13px !important',
          },
          '&.MuiStepLabel-alternativeLabel': {
            fontSize: '11px !important',
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    {/* render theme in the app with a theme provider */}
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);