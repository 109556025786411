import { useSelector } from "react-redux";
import { makeGetRequest } from "request";

export const useDashboardData = () => {
    const user = useSelector((state: any) => state.tepngUser.value);
    const TotalAssignedMaterialReadinessDocuments = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getTotalAssignedMaterialReadinessDocuments",
            params: { 
                freightForwarderId: user?.id,
                startDate: startDate,
                endDate: endDate
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.totalCount })
            .catch((error:any) => console.log(error) );
    }

    const TotalClosedMaterialReadinessDocuments = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getTotalClosedMaterialReadinessDocuments",
            params: { 
                freightForwarderId: user?.id,
                startDate: startDate,
                endDate: endDate
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.totalCount })
            .catch((error:any) => console.log(error) );
    }

    const TotalConsolidatedDocuments = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getTotalConsolidatedDocuments",
            params: { 
                freightForwarderId: user?.id,
                startDate: startDate,
                endDate: endDate
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.totalCount })
            .catch((error:any) => console.log(error) );
    }

    const TotalShippedDocuments = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getTotalShippedDocuments",
            params: { 
                freightForwarderId: user?.id,
                startDate: startDate,
                endDate: endDate
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.totalCount })
            .catch((error:any) => console.log(error) );
    }

    const NumberOfAssignedAndClosedMaterialReadinessDocument = (month: string, year: number): Promise<number> => {
        var request: Record<string, any> = {
            what: "getNumberOfAssignedAndClosedMaterialReadinessDocument",
            params: { 
                userId: user?.id,
                ...(parseInt(month) > 0 && {month: month}),
                year: year
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data })
            .catch((error:any) => console.log(error) );
    }

    const NumberOfConsolidatedAndNotConsolidated = (month: string, year: number): Promise<number> => {
        var request: Record<string, any> = {
            what: "getNumberOfConsolidatedAndNotConsolidated",
            params: { 
                userId: user?.id,
                ...(parseInt(month) > 0 && {month: month}),
                year: year
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data })
            .catch((error:any) => console.log(error) );
    }

    const PercentageChangeOfTotalAssignedMaterialReadinessDocuments = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPercentageChangeOfTotalAssignedMaterialReadinessDocuments",
            params: { 
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data.percentageChange })
            .catch((error:any) => console.log(error) );
    }

    const PercentageChangeOfTotalClosedMaterialReadinessDocuments = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPercentageChangeOfTotalClosedMaterialReadinessDocuments",
            params: { 
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data.percentageChange })
            .catch((error:any) => console.log(error) );
    }

    const PercentageChangeOfTotalConsolidatedDocuments = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPercentageChangeOfTotalConsolidatedDocuments",
            params: { 
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data.percentageChange })
            .catch((error:any) => console.log(error) );
    }

    const PercentageChangeOfTotalShippedDocuments = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPercentageChangeOfTotalShippedDocuments",
            params: { 
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data.percentageChange })
            .catch((error:any) => console.log(error) );
    }

    return { 
        TotalAssignedMaterialReadinessDocuments, 
        TotalClosedMaterialReadinessDocuments, 
        TotalConsolidatedDocuments, 
        TotalShippedDocuments,
        NumberOfAssignedAndClosedMaterialReadinessDocument,
        NumberOfConsolidatedAndNotConsolidated,
        PercentageChangeOfTotalAssignedMaterialReadinessDocuments,
        PercentageChangeOfTotalClosedMaterialReadinessDocuments,
        PercentageChangeOfTotalConsolidatedDocuments,
        PercentageChangeOfTotalShippedDocuments
    }; 
}