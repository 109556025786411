import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js/auto'
import { Line } from 'react-chartjs-2'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

const LineChart = (props) => {    
    const {data, title} = props
    const allDataValues = data.datasets.flatMap(dataset => dataset.data);
    const maxValue = Math.max(...allDataValues);

    let stepSize, max;
    if (maxValue < 100) {
        stepSize = 10;
        max = 100
    }else if (maxValue < 1000) {
        stepSize = 100;
        max = 1000
    } else if (maxValue < 10000) {
        stepSize = 1000; 
        max = 10000
    }

    const getUnit = (maxValue) => {
        if (maxValue >= 1000000) {
          return '(Millions)';
        }
        if (maxValue >= 1000) {
          return '(Thousands)';
        }
        if (maxValue >= 100) {
            return '(Hundreds)';
        }
        // if (maxValue >= 0) {
        //     return 'Tens';
        // }
        return '';
    };
    const options = {
        responsive: true,
        plugins: {
            datalabels: {
                display: true,
                color: '#1e1e1e', // Label text color
                align: 'top', // Position of the label relative to the point
                formatter: (value) => {
                  if (value !== 0) {
                    return value; // Display only non-zero values
                  }
                  return null; // Hide labels for zero values
                },
            },
            legend: {
                position: 'top',
                labels: {
                    color: '#1e1e1e',
                }
            },
            title: {
                display: true,
                text: title,
                color: '#1e1e1e',
            },
        },
        maintainAspectRatio: false,
        // responsiveAnimationDuration: 0,
        layout: {
            padding: 15
        },
        animation: true,
        animationDuration: 2000,
        animationEasing: 'easeInCubic',
        scales: {
            x: {
              grid: {
                display: false, // Disable grid lines for the x-axis                
              },
              ticks: {
                color: '#1e1e1e',
              }
            },
            y: {
                title: {
                    display: true,
                    text: `${getUnit(maxValue)}`,
                },
                grid: {
                    display: false,
                    color: '#d9d9d9',
                },
                ticks: {
                    color: '#1e1e1e',
                    stepSize: stepSize,
                    beginAtZero: true, // Start at 0
                    callback: (value) => {
                        if (Math.floor(value) === value) {
                            return value; // Display only whole numbers
                        }
                    },                    
                },
                max: max
            },
        }
    }    

    return (
        <Line options={options} data={data} />
    )
}

export default LineChart