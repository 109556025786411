import Layout from "../../Layout"
import { customStyles, formatWithCommas, getDataInceptionYearToCurrentYear, getSelectedMonthIndex, Months } from "helpers";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import loading from "../../../assets/images/loading.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRange } from "interfaces/index.interface";
import BarChart from "components/charts/VBar";
import { 
    TotalClosedMaterialReadinessDocument, 
    TotalOpenMaterialReadinessDocument, 
    TotalRejectedMaterialReadinessDocument,
    ClosedOpenAndPendingMaterialReadinessDocument,
    TotalMaterialReadinessDocumentWithPOC,
    GraphOfLeadTimeOfMaterialReadinessDocument,
    PercentageChangeOfTotalClosedMaterialReadinessDocument,
    PercentageChangeOfTotalOpenMaterialReadinessDocument,
    PercentageChangeOfTotalPendingMaterialReadinessDocument
} from "./Requests";
import { 
    GraphOfClosedOpenAndPendingMaterialReadinessDocument, 
    GraphOfLeadTimeOfMaterialReadinessDocumentForAssignmentToFF,
    GraphOfLeadTimeOfPickedMaterialReadinessDocument,
} from "./ChartData";
import LineChart from "components/charts/Line";
import MiniDataCard from "components/MiniDataCard";

const Dashboard =  () => {   
    const currentYear = new Date().getFullYear();
    const [isLoading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const [startDate, setStartDate] = useState<string>(`${currentYear}-01-01`); // initial value before any selection
    const [endDate, setEndDate] = useState<string>(`${currentYear}-12-31`);
    const [dashboardData, setDashboardData] = useState({
        totalOpenMRDs: 0,
        totalClosedMRDs: 0,
        totalRejectedMRDs: 0,
        totalApprovedMRDWithPOC: 0,
        percentageChangeOfTotalClosedMaterialReadinessDocuments: 0,
        percentageChangeOfTotalOpenMaterialReadinessDocuments: 0,
        percentageChangeOfTotalPendingMaterialReadinessDocuments: 0,
        graphOfClosedOpenAndPendingMaterialReadinessDocument: [] as any,
        graphOfLeadTimeOfMaterialReadinessDocument: [] as any,        
    });

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        Promise.all([
            TotalOpenMaterialReadinessDocument(startDate, endDate),
            TotalClosedMaterialReadinessDocument(startDate, endDate),
            TotalRejectedMaterialReadinessDocument(startDate, endDate),
            TotalMaterialReadinessDocumentWithPOC(startDate, endDate),
            ClosedOpenAndPendingMaterialReadinessDocument(getSelectedMonthIndex(selectedMonth), selectedYear),                
            GraphOfLeadTimeOfMaterialReadinessDocument(getSelectedMonthIndex(selectedMonth), selectedYear),
            PercentageChangeOfTotalClosedMaterialReadinessDocument(startDate, endDate),
            PercentageChangeOfTotalOpenMaterialReadinessDocument(startDate, endDate),
            PercentageChangeOfTotalPendingMaterialReadinessDocument(startDate, endDate),
        ])
            .then(([openMRDs, 
                closedMRDs, 
                rejectedMRDs, 
                totalApprovedMRDWithPOC,
                graphOfClosedOpenAndPendingMaterialReadinessDocument, 
                graphOfLeadTimeOfMaterialReadinessDocument,
                percentageChangeOfTotalClosedMaterialReadinessDocuments,
                percentageChangeOfTotalOpenMaterialReadinessDocuments,
                percentageChangeOfTotalPendingMaterialReadinessDocuments
            ]) => {
                setDashboardData({
                    totalOpenMRDs: openMRDs,
                    totalClosedMRDs: closedMRDs,
                    totalRejectedMRDs: rejectedMRDs,
                    totalApprovedMRDWithPOC: totalApprovedMRDWithPOC,
                    graphOfClosedOpenAndPendingMaterialReadinessDocument: graphOfClosedOpenAndPendingMaterialReadinessDocument,
                    graphOfLeadTimeOfMaterialReadinessDocument: graphOfLeadTimeOfMaterialReadinessDocument,
                    percentageChangeOfTotalClosedMaterialReadinessDocuments: percentageChangeOfTotalClosedMaterialReadinessDocuments,
                    percentageChangeOfTotalOpenMaterialReadinessDocuments: percentageChangeOfTotalOpenMaterialReadinessDocuments,
                    percentageChangeOfTotalPendingMaterialReadinessDocuments: percentageChangeOfTotalPendingMaterialReadinessDocuments
                });
            })
            .catch((error) => {
                console.error("Error fetching dashboard data:", error);
                toast.error("Error fetching dashboard data");
            })
            .finally(() => {
                setLoading(false); // Set loading to false when done
            });
        // eslint-disable-next-line        
    }, [startDate, endDate]);

    const updateDateRange = (selectedMonth: string, selectedYear: number): DateRange => {
        let startDate: string;
        let endDate: string;
    
        if (selectedMonth && selectedYear) {
            // If both month and year are selected
            const monthIndex = Months.indexOf(selectedMonth) + 1; // Get the month index (1-12)
            const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`; // Ensure two digits
            startDate = `${selectedYear}-${month}-01`;
            
            // Calculate the last day of the selected month
            const lastDayOfMonth = new Date(selectedYear, monthIndex, 0).getDate();
            endDate = `${selectedYear}-${month}-${lastDayOfMonth}`;
        } else if (selectedYear) {
            // If only year is selected
            startDate = `${selectedYear}-01-01`;
            endDate = `${selectedYear}-12-31`;
        } else {
            // If neither month nor year is selected, return an empty range or handle accordingly
            startDate = '';
            endDate = '';
        }
    
        return { startDate, endDate };
    }

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedMonth = event.target.value;
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        setSelectedMonth(selectedMonth);
        setStartDate(startDate);
        setEndDate(endDate);
    };
    
    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedYear = parseInt(event.target.value, 10);
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        if(!selectedYear) setSelectedMonth("")
        setSelectedYear(selectedYear);
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const bardata = GraphOfClosedOpenAndPendingMaterialReadinessDocument(dashboardData.graphOfClosedOpenAndPendingMaterialReadinessDocument);    
    const linedata = GraphOfLeadTimeOfMaterialReadinessDocumentForAssignmentToFF(dashboardData.graphOfLeadTimeOfMaterialReadinessDocument);
    const linedata2 = GraphOfLeadTimeOfPickedMaterialReadinessDocument(dashboardData.graphOfLeadTimeOfMaterialReadinessDocument);

    const page = "Dashboard Analytics"
    return (
        <Layout title={page}>
            <div className="container">
                <div className="main">
                    <h3 className="page_title">{  }</h3>     
                                        
                    <div className="grid-area">
                        <section className="header">
                            <small className="f-12 text-grey">Month</small>
                            <select value={selectedMonth} onChange={handleMonthChange} disabled={!selectedYear}>
                                <option key="0" value="">All</option>
                                {
                                    Months.map((month: string, index: number) => {
                                        return (
                                            <option key={index}>{month}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className="f-12 text-grey ml-2">Year</small>
                            <select value={selectedYear} onChange={handleYearChange}>
                                <option key="0" value="">All</option>
                                {
                                    getDataInceptionYearToCurrentYear().map((year: number, index: number) => {
                                        return (
                                            <option key={index+1}>{year}</option>
                                        )
                                    })
                                }
                            </select>
                        </section>
                        <div className="card1">
                            <div className="dashboard-card">
                                <div>
                                    <div className="yellow">
                                        <span className="material-symbols-rounded">other_admission</span>
                                        <p>Open Material Readiness Documents </p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalOpenMRDs ?? 0) }</p>
                                <MiniDataCard value={dashboardData.percentageChangeOfTotalOpenMaterialReadinessDocuments} />
                            </div>
                        </div>
                        <div className="card2">
                            <div className="dashboard-card">
                                <div>
                                    <div className="green">
                                        <span className="material-symbols-rounded">task</span>
                                        <p>Approved Material Readiness Documents </p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalClosedMRDs ?? 0) }</p>
                                <MiniDataCard value={dashboardData.percentageChangeOfTotalClosedMaterialReadinessDocuments} />
                            </div>
                        </div>
                        <div className="card3">
                            <div className="dashboard-card">
                                <div>
                                    <div className="red">
                                        <span className="material-symbols-rounded">cancel</span>
                                        <p>Rejected Material Readiness Documents </p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalRejectedMRDs ?? 0)}</p>
                                <MiniDataCard value={dashboardData.percentageChangeOfTotalPendingMaterialReadinessDocuments} />
                            </div>
                        </div>
                        <div className="card4">
                            <div className="dashboard-card">
                                <div>
                                    <div className="blue">
                                        <span className="material-symbols-rounded">inventory</span>
                                        <p>Approved Proof of Collection</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalApprovedMRDWithPOC ?? 0)}</p>
                                {/* <MiniDataCard value={dashboardData.} /> */}
                            </div>
                        </div>
                        
                        <div className="box1">
                            <BarChart data={bardata} title="Monthly Open, Rejected and Approved MRDs"/>
                        </div>
                        <div className="box2">
                            <LineChart data={linedata} title="Monthly Assigned MRD Lead Time" />
                        </div>
                        <div className="box3">
                            <LineChart data={linedata2} title="Monthly Picked MRD Lead Time" />
                        </div>
                    </div> 
                </div>
                <ToastContainer />
                <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                    <div className="loader">
                        <img src={loading} alt="loading" />
                        <p>Loading data...</p>
                    </div>
                </Modal> 
            </div>       
                               
        </Layout>
    )
}

export default Dashboard