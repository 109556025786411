import Layout from "../Layout"
import { customStyles, formatWithCommas, getDataInceptionYearToCurrentYear, getSelectedMonthIndex, Months } from "helpers";
import { TotalAssignedModeOfTransportation, 
    TotalApprovedModeOfTransportation, 
    TotalRejectedModeOfTransportation, 
    TotalReviewedModeOfTransportation,
    NumberOfApprovedAndRejectedModeOfTransportation,
    CostOfApprovedModeOfTransportationSea,
    CostOfApprovedModeOfTransportationAir,
    PercentageChangeOfTotalApprovedModeOfTransportation,
    PercentageChangeOfTotalAssignedModeOfTransportation,
    PercentageChangeOfTotalRejectedModeOfTransportation,
    PercentageChangeOfTotalReviewModeOfTransportation
} from "./Requests";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import loading from "../../assets/images/loading.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRange } from "interfaces/index.interface";
import { graphOfNumberOfApprovedAndRejectedModeOfTransportation, graphOfCostOfApprovedModeOfTransportationSea, graphOfCostOfApprovedModeOfTransportationAir } from "./ChartData";
import BarChart from "components/charts/VBar";
// import LineChart from "components/charts/Line";
import MiniDataCard from "components/MiniDataCard";

const Dashboard =  () => {
    const currentYear = new Date().getFullYear()
    const [isLoading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const [startDate, setStartDate] = useState<string>(`${currentYear}-01-01`); // initial value before any selection
    const [endDate, setEndDate] = useState<string>(`${currentYear}-12-31`);
    const [dashboardData, setDashboardData] = useState({
        totalAssignedMOT: 0,
        totalApprovedMOT: 0,
        totalRejectedMOT: 0,
        totalReviewedMOT: 0,
        percentageChangeOfTotalApprovedModeOfTransportation: 0,
        percentageChangeOfTotalAssignedModeOfTransportation: 0,
        percentageChangeOfTotalRejectedModeOfTransportation: 0,
        percentageChangeOfTotalReviewModeOfTransportation: 0,
        graphOfNumberOfApprovedAndRejectedModeOfTransportation: [] as any,
        graphOfCostOfApprovedModeOfTransportationSea: [] as any,
        graphOfCostOfApprovedModeOfTransportationAir: [] as any,
    });

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
        
            Promise.all([
                TotalAssignedModeOfTransportation(startDate, endDate),
                TotalApprovedModeOfTransportation(startDate, endDate),
                TotalRejectedModeOfTransportation(startDate, endDate),
                TotalReviewedModeOfTransportation(startDate, endDate),
                PercentageChangeOfTotalApprovedModeOfTransportation(startDate, endDate),
                PercentageChangeOfTotalAssignedModeOfTransportation(startDate, endDate),
                PercentageChangeOfTotalRejectedModeOfTransportation(startDate, endDate),
                PercentageChangeOfTotalReviewModeOfTransportation(startDate, endDate),
                NumberOfApprovedAndRejectedModeOfTransportation(getSelectedMonthIndex(selectedMonth), selectedYear),
                CostOfApprovedModeOfTransportationSea(getSelectedMonthIndex(selectedMonth), selectedYear),
                CostOfApprovedModeOfTransportationAir(getSelectedMonthIndex(selectedMonth), selectedYear)
            ])
                .then(([totalAssignedMOT, 
                    totalApprovedMOT, 
                    totalRejectedMOT, 
                    totalReviewedMOT, 
                    percentageChangeOfTotalApprovedModeOfTransportation,
                    percentageChangeOfTotalAssignedModeOfTransportation,    
                    percentageChangeOfTotalRejectedModeOfTransportation,
                    percentageChangeOfTotalReviewModeOfTransportation,
                    graphOfNumberOfApprovedAndRejectedModeOfTransportation, 
                    graphOfCostOfApprovedModeOfTransportationSea,                    
                    graphOfCostOfApprovedModeOfTransportationAir,                    
                ]) => {
                    setDashboardData({
                        totalAssignedMOT: totalAssignedMOT,
                        totalApprovedMOT: totalApprovedMOT,
                        totalRejectedMOT: totalRejectedMOT,
                        totalReviewedMOT: totalReviewedMOT,
                        percentageChangeOfTotalApprovedModeOfTransportation: percentageChangeOfTotalApprovedModeOfTransportation,
                        percentageChangeOfTotalAssignedModeOfTransportation: percentageChangeOfTotalAssignedModeOfTransportation,
                        percentageChangeOfTotalRejectedModeOfTransportation: percentageChangeOfTotalRejectedModeOfTransportation,
                        percentageChangeOfTotalReviewModeOfTransportation: percentageChangeOfTotalReviewModeOfTransportation,
                        graphOfNumberOfApprovedAndRejectedModeOfTransportation: graphOfNumberOfApprovedAndRejectedModeOfTransportation,
                        graphOfCostOfApprovedModeOfTransportationSea: graphOfCostOfApprovedModeOfTransportationSea,
                        graphOfCostOfApprovedModeOfTransportationAir: graphOfCostOfApprovedModeOfTransportationAir,
                    });
                })
                .catch((error) => {
                    console.error("Error fetching dashboard data:", error);
                    toast.error("Error fetching dashboard data");
                })
                .finally(() => {
                    setLoading(false); // Set loading to false when done
                });
    }, [startDate, endDate, selectedMonth, selectedYear]);

    const updateDateRange = (selectedMonth: string, selectedYear: number): DateRange => {
        let startDate: string;
        let endDate: string;
    
        if (selectedMonth && selectedYear) {
            // If both month and year are selected
            const monthIndex = Months.indexOf(selectedMonth) + 1; // Get the month index (1-12)
            const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`; // Ensure two digits
            startDate = `${selectedYear}-${month}-01`;
            
            // Calculate the last day of the selected month
            const lastDayOfMonth = new Date(selectedYear, monthIndex, 0).getDate();
            endDate = `${selectedYear}-${month}-${lastDayOfMonth}`;
        } else if (selectedYear) {
            // If only year is selected
            startDate = `${selectedYear}-01-01`;
            endDate = `${selectedYear}-12-31`;
        } else {
            // If neither month nor year is selected, return an empty range or handle accordingly
            startDate = '';
            endDate = '';
        }
    
        return { startDate, endDate };
    }

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedMonth = event.target.value;
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        setSelectedMonth(selectedMonth);
        setStartDate(startDate);
        setEndDate(endDate);
    };
    
    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedYear = parseInt(event.target.value, 10);
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        if(!selectedYear) setSelectedMonth("")
        setSelectedYear(selectedYear);
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const linedata = graphOfNumberOfApprovedAndRejectedModeOfTransportation(dashboardData?.graphOfNumberOfApprovedAndRejectedModeOfTransportation)
    const bardata = graphOfCostOfApprovedModeOfTransportationSea(dashboardData?.graphOfCostOfApprovedModeOfTransportationSea)
    const bardata2 = graphOfCostOfApprovedModeOfTransportationAir(dashboardData?.graphOfCostOfApprovedModeOfTransportationAir)

    console.log(bardata)
    console.log(bardata2)

    const page = "Dashboard Analytics"    
    return (
        <Layout title={page}>
            <div className="container">
                <div className="main">
                    <h3 className="page_title">{  }</h3>                                             
                    <div className="grid-area-4 custom">
                        <section className="header">
                            <small className="f-12 text-grey ml-2">Month</small>
                            <select value={selectedMonth} onChange={handleMonthChange} disabled={!selectedYear}>
                                <option key="0" value="">All</option>
                                {
                                    Months.map((month: string, index: number) => {
                                        return (
                                            <option key={index}>{month}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className="f-12 text-grey ml-2">Year</small>
                            <select value={selectedYear} onChange={handleYearChange}>
                                <option key="0" value="">All</option>
                                {
                                    getDataInceptionYearToCurrentYear().map((year: number, index: number) => {
                                        return (
                                            <option key={index+1}>{year}</option>
                                        )
                                    })
                                }
                            </select>
                        </section>
                        <div className="card1">
                            <div className="dashboard-card">
                                <div>
                                    <div className="blue">
                                        <span className="material-symbols-rounded">article</span>
                                        <p>Reviewed MOT Change Request</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalReviewedMOT ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalReviewModeOfTransportation} />
                            </div>
                        </div>
                        <div className="card2">
                            <div className="dashboard-card">
                                <div>
                                    <div className="green">
                                        <span className="material-symbols-rounded">verified</span>
                                        <p>Approved MOT Change Request</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalApprovedMOT ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalApprovedModeOfTransportation} />
                            </div>                           
                        </div>
                        <div className="card3">
                            <div className="dashboard-card">
                                <div>
                                    <div className="red">
                                        <span className="material-symbols-rounded">cancel</span>
                                        <p>Rejected MOT Change Request</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalRejectedMOT ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalRejectedModeOfTransportation} />
                            </div>
                        </div>                        
                        <div className="card4">                            
                            <div className="dashboard-card">
                                <div>
                                    <div className="purple">
                                        <span className="material-symbols-rounded">other_admission</span>
                                        <p>Assigned MOT Change Request</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalAssignedMOT ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalAssignedModeOfTransportation} />
                            </div>  
                        </div>
                        
                        <div className="box1">
                            <BarChart data={linedata} title="Monthly Approved and Rejected MOT Change Request" />
                        </div>
                        <div className="box2">
                            <BarChart data={bardata} title={`Monthly Approved MOT Change Request Sea Freight Cost`} />
                        </div>
                        <div className="box3">
                            <BarChart data={bardata2} title={`Monthly Approved MOT Change Request Air Freight Cost`} />
                        </div>
                    </div> 
                </div>
                <ToastContainer />
                <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                    <div className="loader">
                        <img src={loading} alt="loading" />
                        <p>Loading data...</p>
                    </div>
                </Modal> 
            </div>                            
        </Layout>
    )
}

export default Dashboard