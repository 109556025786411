import { Months } from "helpers";


export const graphOfNumberOfApprovedAndRejectedModeOfTransportation = (result: { approved: { month: number; count: number }[]; rejected: { month: number; count: number }[]; }) => {
    const approvedData = new Array(12).fill(0);
    const rejectedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.approved?.forEach((item: { month: number; count: number }) => {
        approvedData[item.month - 1] = item.count;
    });

    result?.rejected?.forEach((item: { month: number; count: number })  => {
        rejectedData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Approved',
                data: approvedData,
                borderColor: 'rgb(0, 212, 39)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(0, 234, 0, 0.2)'
            },
            {
                label: 'Rejected',
                data: rejectedData,
                borderColor: 'rgb(225, 103, 103)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(234, 103, 103, 0.2)'

            }
        ]
    };
}

export const graphOfCostOfApprovedModeOfTransportationSea = (result: { NGN: { month: number; count: number }[]; USD: { month: number; count: number }[]; GBP: { month: number; count: number }[]; EUR: { month: number; count: number }[]; }) => {
    const usdData = new Array(12).fill(0);
    const ngnData = new Array(12).fill(0);
    const gbpData = new Array(12).fill(0);
    const eurData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.NGN?.forEach((item: { month: number; count: number }) => {
        ngnData[item.month - 1] = item.count;
    });

    result?.USD?.forEach((item: { month: number; count: number })  => {
        usdData[item.month - 1] = item.count;
    });

    result?.GBP?.forEach((item: { month: number; count: number })  => {
        gbpData[item.month - 1] = item.count;
    });

    result?.EUR?.forEach((item: { month: number; count: number })  => {
        eurData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'NGN',
                fill: true,
                data: ngnData,
                borderColor: 'rgb(0, 113, 212)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(0, 82, 234, 0.2)'
            },
            {
                label: 'USD',
                fill: true,
                data: usdData,
                borderColor: 'rgb(103, 225, 127)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(103, 225, 127, 0.2)'
            },
            {
                label: 'GBP',
                fill: true,
                data: gbpData,
                borderColor: 'rgb(223, 225, 103)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(219, 234, 1, 0.2)'
            },
            {
                label: 'EUR',
                fill: true,
                data: eurData,
                borderColor: 'rgb(180, 103, 225)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(180, 103, 225, 0.2)'
            },
        ]
    };
}

export const graphOfCostOfApprovedModeOfTransportationAir = (result: { NGN: { month: number; count: number }[]; USD: { month: number; count: number }[]; GBP: { month: number; count: number }[]; EUR: { month: number; count: number }[]; }) => {
    const usdData = new Array(12).fill(0);
    const ngnData = new Array(12).fill(0);
    const gbpData = new Array(12).fill(0);
    const eurData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.NGN?.forEach((item: { month: number; count: number }) => {
        ngnData[item.month - 1] = item.count;
    });

    result?.USD?.forEach((item: { month: number; count: number })  => {
        usdData[item.month - 1] = item.count;
    });

    result?.GBP?.forEach((item: { month: number; count: number })  => {
        gbpData[item.month - 1] = item.count;
    });

    result?.EUR?.forEach((item: { month: number; count: number })  => {
        eurData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'NGN',
                fill: true,
                data: ngnData,
                borderColor: 'rgb(0, 113, 212)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(0, 82, 234, 0.2)'
            },
            {
                label: 'USD',
                fill: true,
                data: usdData,
                borderColor: 'rgb(103, 225, 127)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(103, 225, 127, 0.2)'
            },
            {
                label: 'GBP',
                fill: true,
                data: gbpData,
                borderColor: 'rgb(223, 225, 103)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(219, 234, 1, 0.2)'
            },
            {
                label: 'EUR',
                fill: true,
                data: eurData,
                borderColor: 'rgb(180, 103, 225)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(180, 103, 225, 0.2)'
            },
        ]
    };
}