import { handlePercentageChange } from "helpers"


const MiniDataCard = (props: {value: number}) => {
    const { value } = props
    return (
        <span className={handlePercentageChange(value)?.color}>
            <span className="material-symbols-rounded">{handlePercentageChange(value)?.icon}</span>
            {handlePercentageChange(value)?.value} in the past 7 days
        </span>
    )
}

export default MiniDataCard