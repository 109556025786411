import { Months } from "helpers";


export const graphOfNumberOfIssuedPickedAndOutstandingMRD = (result: { issued: { month: number; count: number }[]; picked: { month: number; count: number }[]; outstanding: { month: number; count: number }[] }) => {
    const issuedData = new Array(12).fill(0);
    const pickedData = new Array(12).fill(0);
    const outstandingData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.issued?.forEach((item: { month: number; count: number }) => {
        issuedData[item.month - 1] = item.count;
    });

    result?.picked?.forEach((item: { month: number; count: number })  => {
        pickedData[item.month - 1] = item.count;
    });

    result?.outstanding?.forEach((item: { month: number; count: number })  => {
        outstandingData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Issued',
                data: issuedData,
                borderColor: 'rgba(234, 168, 1)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(234, 168, 1, 0.2)'
            },
            {
                label: 'Rejected',
                data: outstandingData,
                borderColor: 'rgba(255, 0, 0)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(255, 0, 0, 0.2)'
            },
            {
                label: 'Picked',
                data: pickedData,
                borderColor: 'rgb(75, 128, 192)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgb(75, 128, 192, 0.2)'
            },
            
        ]
    };
}

export const graphOfNumberOfCompletedAndNotCompletedMRD = (result: { completed: { month: number; count: number }[]; inProgress: { month: number; count: number }[]; partiallyCompleted: { month: number; count: number }[]; notCompleted: { month: number; count: number }[] }) => {
    const completedData = new Array(12).fill(0);
    const inProgressData = new Array(12).fill(0);
    const partiallyCompletedData = new Array(12).fill(0);
    const notCompletedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.completed?.forEach((item: { month: number; count: number }) => {
        completedData[item.month - 1] = item.count;
    });

    result?.inProgress?.forEach((item: { month: number; count: number })  => {
        inProgressData[item.month - 1] = item.count;
    });

    result?.partiallyCompleted?.forEach((item: { month: number; count: number })  => {
        partiallyCompletedData[item.month - 1] = item.count;
    });

    result?.notCompleted?.forEach((item: { month: number; count: number })  => {
        notCompletedData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'In Progress',
                type: "bar",
                data: inProgressData,
                borderColor: 'rgb(75, 128, 192)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(75, 128, 192, 0.2)'
            },
            {
                label: 'Partially Delivered',
                type: "bar",
                data: partiallyCompletedData,
                borderColor: 'rgba(234, 168, 1)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(234, 168, 1, 0.2)'
            },
            {
                label: 'Delivered',
                type: "bar",
                data: completedData,
                borderColor: 'rgb(75, 192, 83)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(75, 192, 83, 0.2)'
            },            
            {
                label: 'Not Delivered',
                type: "bar",
                data: notCompletedData,
                borderColor: 'rgba(255, 0, 0)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(255, 0, 0, 0.2)'
            }
        ]
    };
}