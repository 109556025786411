import logo from "../../assets/images/logo-2.png"
import loading from "../../assets/images/loading.gif"
import { loginTepngUser } from "../../request";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTepngUser } from "../../store/tepngUser";
import { setRoles } from "../../store/roles";
import { setPermissions } from "../../store/permissions";
import { useEffect } from 'react';

const Authentication =  () => {
    const navigate = useNavigate()   
    const dispatch = useDispatch()

    const redirectUser = (roles: any) => {
        //Redirect user after login        
        const redirectURL = sessionStorage.getItem("redirectURL")
        if(redirectURL !== null) window.location.replace(redirectURL)
        else navigate(`/${roles[0].toLowerCase().replaceAll(" ","")}/dashboard`)
        sessionStorage.removeItem("redirectURL")
    }

    useEffect(() => {
        loginTepngUser().then((res: any) => {
            if(res) {
                dispatch(setTepngUser(res.userDetails))
                dispatch(setRoles(res.roles))
                dispatch(setPermissions(res.permissions))
                redirectUser(res.roles)
            } 
        })  
        // if(accessToken == null || new Date(accessToken?.expiration) < new Date()) {
        //     const urlParams = new URLSearchParams(window.location.search);
        //     if (urlParams.get("azureauthredirect") === '1') {
        //         TpengLogin()
        //     }else {
        //         RefreshToken(dispatch)
        //             ?.then((res:any) => redirectUser(res.roles))
        //             .catch((error:any) => console.log("error: ",error));
        //     }    
        // } 
        // eslint-disable-next-line
    }, [])

    return (
        <div className="authentication-container">
            <div className="authentication-modal">
                <div className="inner">
                    <img width="50px" src={logo} alt="" />
                    <h3>Transit Tracker System</h3>   
                    <div style={{marginTop: "48px"}}>
                        <img width="60px" src={loading} alt="" />
                        <p className="small-text">Checking your Microsoft AD credentials to access the application</p>
                    </div>                

                    {/* {!isLoading && <div style={customStyle}>
                        <p className="line-text">Login as</p>
                        <button onClick={() => TpengLogin()}>TotalEnergies Staff</button>
                    </div>} */}
                </div>
            </div>
        </div>
    )
}

export default Authentication