import { useNavigate } from "react-router-dom"
import image from "../../assets/images/info-icon.png"
import { useState } from "react"
import { makePostRequest } from "request"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { setTepngUser } from "../../store/tepngUser";
import { setRoles } from "../../store/roles";
import { setPermissions } from "../../store/permissions";
// import { useMsal } from "@azure/msal-react"

const VendorCodeValidation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [vendorCode, setVendorCode] = useState("")
    // const { instance } = useMsal();
    // const handleADLogout = () => {
    //     instance.logoutRedirect().catch((error) => console.error(error));
    // };

    const handleValidateVendorCode = () => {
        setIsLoading(true)
        const data = {
            vendorCode: vendorCode
        }
        var request:Record<string, any> = {
            what: "VendorCodeValidation",
            data: data
        }
    
        makePostRequest(request)
            .then((response: any) => {
                setIsLoading(false)
                toast.success(response.msg)

                const res = response.data.data
                if(res){
                    dispatch(setTepngUser(res.user))
                    dispatch(setRoles(res.roles))
                    dispatch(setPermissions(res.permissions))
                    // check if seesion storage vendorCode is set, if not set, set it
                    if(sessionStorage.getItem('vendorCode') === null) sessionStorage.setItem('vendorCode', res.user.vendorCode)   

                    // Redirect user
                    const redirectURL = sessionStorage.getItem("redirectURL")
                    const roles = res.roles
                    if(redirectURL !== null) window.location.replace(redirectURL)
                    else navigate(`/${roles[0].toLowerCase().replaceAll(" ","")}/dashboard`)
                    sessionStorage.removeItem("redirectURL")
                }
            }).catch((error:any) => {toast.error(error.msg); setIsLoading(false)});
    }

    return (
        <>
            <div className="blocked-container">
                <div >
                    <img src={image} alt="unauthorized" width="40px" />
                    <h3>Vendor Code Required</h3>
                    <p>Kindly provide your valid code to access the portal.</p>
                    <div className="d-flex-2 gap-2">
                        <input 
                        disabled={isLoading} 
                        className="custom-input" 
                        type="text" style={{height: "35px"}} 
                        placeholder="Enter Vendor Code" 
                        onChange={(e) => setVendorCode(e.target.value)}
                        />
                        <button onClick={() => handleValidateVendorCode()} disabled={isLoading} className="custom-button orange" style={{margin: 0}}>{isLoading ? "Validating..." : "Continue"}</button>
                    </div>

                    <button onClick={() => navigate("/unauthorized") } className="link-button blue" style={{padding: "12px 16px", margin: "24px auto", textDecoration: "underline"}}>I don't have a vendor code</button>
                    
                </div>
            </div>    
            <ToastContainer />    
        </>
    )
}

export default VendorCodeValidation