// import BarChart from "components/charts/VBar";
// import PieChart from "components/charts/Pie";
// import LineChart from "components/charts/Line";
import Layout from "../../Layout"
import { customStyles, formatWithCommas, getDataInceptionYearToCurrentYear, getSelectedMonthIndex, Months } from "helpers";
import { 
    TotalMaterialReadinessDocumentPendingPickup, 
    TotalNumberOfDeliveredPurchaseOrderLineItems, 
    TotalPendingMaterialReadinessDocument, 
    TotalAssignedShippmentAwaitingDelivery, 
    GraphOfPurchaseOrders,
    GraphOfGeneratedAndApprovedProofOfCollection,
    GraphOfLeadTimeForMaterialReadiness,
    PercentageChangeOfTotalMaterialReadinessDocumentPendingPickup,
    PercentageChangeOfTotalShippmentAssignedToLCA,
    PercentageChangeOfTotalPendingMaterialReadinessDocument
} from "./Requests";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import loading from "../../../assets/images/loading.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRange } from "interfaces/index.interface";
import { graphOfPurchaseOrders, graphOfGeneratedAndApprovedProofOfCollection, graphOfLeadTimeForMaterialReadiness } from "./ChartData";
import LineChart from "components/charts/Line";
import BarChart from "components/charts/VBar";
import MiniDataCard from "components/MiniDataCard";

const Dashboard =  () => {
    const currentYear = new Date().getFullYear();
    const [isLoading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const [startDate, setStartDate] = useState<string>(`${currentYear}-01-01`); // initial value before any selection
    const [endDate, setEndDate] = useState<string>(`${currentYear}-12-31`);
    const [dashboardData, setDashboardData] = useState({
        totalMRDPendingPickup: 0,
        totalNumberOfDeliveredPurchaseOrderLineItems: 0,
        totalPendingMRDs: 0,
        totalAssignedShippmentAwaitingDelivery: 0,
        percentageChangeOfTotalMaterialReadinessDocumentPendingPickup: 0,
        percentageChangeOfTotalShippmentAssignedToLCA: 0,
        percentageChangeOfTotalPendingMaterialReadinessDocument: 0,
        graphOfPurchaseOrders: [] as any,
        graphOfGeneratedAndApprovedProofOfCollection: [] as any,
        graphOfLeadTimeForMaterialReadiness: [] as any
    });


    useEffect(() => {
        setLoading(true); // Set loading to true when fetching data
            Promise.all([
                TotalMaterialReadinessDocumentPendingPickup(startDate, endDate),
                TotalNumberOfDeliveredPurchaseOrderLineItems(startDate, endDate),
                TotalPendingMaterialReadinessDocument(startDate, endDate),
                TotalAssignedShippmentAwaitingDelivery(startDate, endDate),
                PercentageChangeOfTotalMaterialReadinessDocumentPendingPickup(startDate, endDate),
                PercentageChangeOfTotalShippmentAssignedToLCA(startDate, endDate),
                PercentageChangeOfTotalPendingMaterialReadinessDocument(startDate, endDate),
                GraphOfPurchaseOrders(getSelectedMonthIndex(selectedMonth), selectedYear),
                GraphOfGeneratedAndApprovedProofOfCollection(getSelectedMonthIndex(selectedMonth), selectedYear),
                GraphOfLeadTimeForMaterialReadiness(getSelectedMonthIndex(selectedMonth), selectedYear)
            ])
                .then(([totalMRDPendingPickup1, 
                    totalNumberOfDeliveredPurchaseOrderLineItems, 
                    totalPendingMRDs, 
                    totalAssignedShippmentAwaitingDelivery,
                    percentageChangeOfTotalMaterialReadinessDocumentPendingPickup,
                    percentageChangeOfTotalShippmentAssignedToLCA,
                    percentageChangeOfTotalPendingMaterialReadinessDocument,
                    graphOfPurchaseOrders,
                    graphOfGeneratedAndApprovedProofOfCollection,
                    graphOfLeadTimeForMaterialReadiness
                ]) => {
                    setDashboardData({
                        totalMRDPendingPickup: totalMRDPendingPickup1,
                        totalNumberOfDeliveredPurchaseOrderLineItems: totalNumberOfDeliveredPurchaseOrderLineItems,
                        totalPendingMRDs: totalPendingMRDs,
                        totalAssignedShippmentAwaitingDelivery: totalAssignedShippmentAwaitingDelivery,
                        percentageChangeOfTotalMaterialReadinessDocumentPendingPickup: percentageChangeOfTotalMaterialReadinessDocumentPendingPickup,
                        percentageChangeOfTotalShippmentAssignedToLCA: percentageChangeOfTotalShippmentAssignedToLCA,
                        percentageChangeOfTotalPendingMaterialReadinessDocument: percentageChangeOfTotalPendingMaterialReadinessDocument,
                        graphOfPurchaseOrders: graphOfPurchaseOrders,
                        graphOfGeneratedAndApprovedProofOfCollection: graphOfGeneratedAndApprovedProofOfCollection,
                        graphOfLeadTimeForMaterialReadiness: graphOfLeadTimeForMaterialReadiness
                    });
                })
                .catch((error) => {
                    console.error("Error fetching dashboard data:", error);
                    toast.error("Error fetching dashboard data");
                })
                .finally(() => {
                    setLoading(false); // Set loading to false when done
                });
    }, [startDate, endDate, selectedMonth, selectedYear]);

    
    const updateDateRange = (selectedMonth: string, selectedYear: number): DateRange => {
        let startDate: string;
        let endDate: string;
    
        if (selectedMonth && selectedYear) {
            // If both month and year are selected
            const monthIndex = Months.indexOf(selectedMonth) + 1; // Get the month index (1-12)
            const month = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`; // Ensure two digits
            startDate = `${selectedYear}-${month}-01`;
            
            // Calculate the last day of the selected month
            const lastDayOfMonth = new Date(selectedYear, monthIndex, 0).getDate();
            endDate = `${selectedYear}-${month}-${lastDayOfMonth}`;
        } else if (selectedYear) {
            // If only year is selected
            startDate = `${selectedYear}-01-01`;
            endDate = `${selectedYear}-12-31`;
        } else {
            // If neither month nor year is selected, return an empty range or handle accordingly
            startDate = '';
            endDate = '';
        }
    
        return { startDate, endDate };
    }

    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedMonth = event.target.value;
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        setSelectedMonth(selectedMonth);
        setStartDate(startDate);
        setEndDate(endDate);
    };
    
    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedYear = parseInt(event.target.value, 10);
        const { startDate, endDate } = updateDateRange(selectedMonth, selectedYear);
        if(!selectedYear) setSelectedMonth("")
        setSelectedYear(selectedYear);
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const linedata = graphOfPurchaseOrders(dashboardData?.graphOfPurchaseOrders)
    const bardata = graphOfGeneratedAndApprovedProofOfCollection(dashboardData?.graphOfGeneratedAndApprovedProofOfCollection)
    const linedata2 = graphOfLeadTimeForMaterialReadiness(dashboardData?.graphOfLeadTimeForMaterialReadiness)

    const page = "Dashboard Analytics"    
    return (
        <Layout title={page}>
            <div className="container">
                <div className="main">
                    <h3 className="page_title">{  }</h3>     
                                        
                    <div className="grid-area">
                        <section className="header">
                            <small className="f-12 text-grey">Month</small>
                            <select value={selectedMonth} onChange={handleMonthChange} disabled={!selectedYear}>
                                <option key="0" value="">All</option>
                                {
                                    Months.map((month: string, index: number) => {
                                        return (
                                            <option key={index}>{month}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className="f-12 text-grey ml-2">Year</small>
                            <select value={selectedYear} onChange={handleYearChange}>
                                <option key="0" value="">All</option>
                                {
                                    getDataInceptionYearToCurrentYear().map((year: number, index: number) => {
                                        return (
                                            <option key={index+1}>{year}</option>
                                        )
                                    })
                                }
                            </select>
                        </section>
                        <div className="card1">
                            <div className="dashboard-card">
                                <div>
                                    <div className="yellow">
                                        <span className="material-symbols-rounded">post_add</span>
                                        <p>Unassigned Material Readiness Documents </p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalPendingMRDs ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalPendingMaterialReadinessDocument} />
                            </div>                            
                        </div>
                        <div className="card4">
                            <div className="dashboard-card">
                                <div>
                                    <div className="green">
                                        <span className="material-symbols-rounded">check_box</span>
                                        <p>Delivered Purchase Order Line Items</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalNumberOfDeliveredPurchaseOrderLineItems ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalShippmentAssignedToLCA} />
                            </div>
                        </div>
                        <div className="card2">
                            <div className="dashboard-card">
                                <div>
                                    <div className="blue">
                                        <span className="material-symbols-rounded">local_shipping</span>
                                        <p>Assigned MRDs Pending Freight Forwarder Pickup</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalMRDPendingPickup ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalMaterialReadinessDocumentPendingPickup} />
                            </div>
                        </div>
                        <div className="card3">
                            <div className="dashboard-card">
                                <div>
                                    <div className="purple">
                                        <span className="material-symbols-rounded">other_admission</span>
                                        <p>Total Assigned Shipment Awaiting Delivery</p>
                                    </div>
                                </div>
                                <p>{formatWithCommas(dashboardData?.totalAssignedShippmentAwaitingDelivery ?? 0) }</p>
                                <MiniDataCard value={dashboardData?.percentageChangeOfTotalShippmentAssignedToLCA} />
                            </div>
                        </div>
                        
                        <div className="box1">
                            <BarChart data={linedata} title="Monthly Purchase Order Line Items"/>                            
                        </div>
                        <div className="box2">
                            <BarChart data={bardata} title="Monthly Generated and Approved Proof of Collection" />
                        </div>
                        <div className="box3">
                            <LineChart data={linedata2} title="Monthly MRD Assignment to POC Lead Time" />
                        </div>
                    </div> 
                </div>
                <ToastContainer />
                <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                    <div className="loader">
                        <img src={loading} alt="loading" />
                        <p>Loading data...</p>
                    </div>
                </Modal> 
            </div>                            
        </Layout>
    )
}

export default Dashboard