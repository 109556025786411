import { makeGetRequest } from "request";

export const TotalOpenMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalOpenMaterialReadinessDocument",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalClosedMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalClosedMaterialReadinessDocument",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalRejectedMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalPendingMaterialReadinessDocument",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalMaterialReadinessDocumentWithPOC = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalMaterialReadinessDocumentWithPOC",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const ClosedOpenAndPendingMaterialReadinessDocument = (month: string, year: number): Promise<number> => {
    var request: Record<string, any> = {
        what: "getGraphOfClosedOpenAndPendingMaterialReadinessDocument",
        params: { 
            ...(parseInt(month) > 0 && {month: month}),
            year: year,
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data })
        .catch((error:any) => console.log(error) );
}

export const GraphOfLeadTimeOfMaterialReadinessDocument = (month: string, year: number): Promise<number> => {
    var request: Record<string, any> = {
        what: "getGraphOfLeadTimeOfMaterialReadinessDocument",
        params: { 
            ...(parseInt(month) > 0 && {month: month}),
            year: year,
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalOpenMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalOpenMaterialReadinessDocument",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalClosedMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalClosedMaterialReadinessDocument",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalPendingMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalPendingMaterialReadinessDocument",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}