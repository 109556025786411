import { PaginationProps } from "../interfaces/index.interface"


const Pagination = (props: PaginationProps) => {
    const {
        currentPage,
        totalPages,
        itemsPerPage,
        handlePrevious,
        handleNext,
        setCurrentPage,
        getPageNumbers,        
        setItemsPerPage,
        startIndex,
      } = props
    return (
        <div className="pagination-group">
            <div>
                <select value={itemsPerPage} onChange={(e) => { setItemsPerPage(Number(e.target.value)); }}>
                    <option value="10">Show 10 Entries</option>
                    <option value="25">Show 25 Entries</option>
                    <option value="50">Show 50 Entries</option>
                    <option value="100">Show 100 Entries</option>
                </select>
                <p className="page-num">Page {currentPage} of {totalPages}</p>
            </div>
            <div className="pagination">
                <button type="button" className="page-item" onClick={handlePrevious} disabled={currentPage === 1}>Previous</button>
                {
                    getPageNumbers()
                        .slice(startIndex, startIndex + 4) // Show the next set of 4 page numbers
                        .map((pageNumber) => (
                            <button
                                type="button"
                                key={pageNumber}
                                className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                                onClick={() => setCurrentPage(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        ))
                }
                {startIndex + 5 < totalPages && <button type="button" className='page-item'>...</button>}

                {startIndex + 4 < totalPages &&
                    <button
                        type="button"
                        className={`page-item ${currentPage === totalPages ? 'active' : ''}`}
                        onClick={() => setCurrentPage(totalPages)}
                    >
                        {totalPages}
                    </button>
                }
                <button type="button" className="page-item" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    )
}

export default Pagination