import { makeGetRequest } from "request";

export const TotalNumberOfDeliveredPurchaseOrderLineItems = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalNumberOfDeliveredPurchaseOrderLineItems",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalAssignedShippmentAwaitingDelivery = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalAssignedShippmentAwaitingDelivery",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalMaterialReadinessDocumentPendingPickup = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalMaterialReadinessDocumentPendingPickup",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalPendingMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalPendingMaterialReadinessDocumentTransit",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const GraphOfPurchaseOrders = (month: string, year: number): Promise<number> => {
    var request: Record<string, any> = {
        what: "getGraphOfPurchaseOrders",
        params: { 
            ...(parseInt(month) > 0 && {month: month}),
            year: year,
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data })
        .catch((error:any) => console.log(error) );
}

export const GraphOfGeneratedAndApprovedProofOfCollection = (month: string, year: number): Promise<number> => {
    var request: Record<string, any> = {
        what: "getGraphOfGeneratedAndApprovedProofOfCollection",
        params: { 
            ...(parseInt(month) > 0 && {month: month}),
            year: year,
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data })
        .catch((error:any) => console.log(error) );
}

export const GraphOfLeadTimeForMaterialReadiness = (month: string, year: number): Promise<number> => {
    var request: Record<string, any> = {
        what: "getGraphOfLeadTimeForMaterialReadiness",
        params: { 
            ...(parseInt(month) > 0 && {month: month}),
            year: year,
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { 
            const res = response.data.data
            const assignmentToPOCData = new Array(12).fill(0);   

            res.assignedToPOC?.forEach((item: { month: number; count: number }) => {                
                assignmentToPOCData[item.month - 1] = (item.count/24).toFixed(2); // Convert the count to a string and round it to 2 decimal places before adding it to the data array.item.count;
            });

            return {
                ...response.data.data, // Spread all original data
                assignedToPOC: assignmentToPOCData.map((data: any, index: number) => ({ month: index + 1, count: Number(data) }))
            };
        })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalPendingMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalPendingMaterialReadinessDocumentTransit",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalMaterialReadinessDocumentPendingPickup = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalMaterialReadinessDocumentPendingPickup",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalShippmentAssignedToLCA = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalShippmentAssignedToLCA",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}