import { Months } from "helpers";


export const graphOfPurchaseOrders = (result: { completed: { month: number; count: number }[]; partiallyCompleted: { month: number; count: number }[]; notCompleted: { month: number; count: number }[] }) => {
    const completedData = new Array(12).fill(0);
    const partiallyCompletedData = new Array(12).fill(0);
    const notCompletedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.completed?.forEach((item: { month: number; count: number }) => {
        completedData[item.month - 1] = item.count;
    });

    result?.partiallyCompleted?.forEach((item: { month: number; count: number })  => {
        partiallyCompletedData[item.month - 1] = item.count;
    });

    result?.notCompleted?.forEach((item: { month: number; count: number })  => {
        notCompletedData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Fulfilled',
                type: "bar",
                data: completedData,
                borderColor: 'rgb(75, 192, 87)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(75, 192, 87, 0.2)'
            },              
            {
                label: 'Partially Fulfilled',
                type: "bar",
                data: partiallyCompletedData,
                borderColor: 'rgba(234, 133, 1)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(234, 133, 1, 0.2)'
            },                    
            {
                label: 'Unfulfilled',
                type: "bar",
                data: notCompletedData,
                borderColor: 'rgba(234, 1, 1)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(234, 1, 1, 0.2)'
            },
        ]
    };
}

export const graphOfGeneratedAndApprovedProofOfCollection = (result: { approved: { month: number; count: number }[]; generated: { month: number; count: number }[]; }) => {
    const approvedData = new Array(12).fill(0);
    const generatedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.approved?.forEach((item: { month: number; count: number }) => {
        approvedData[item.month - 1] = item.count;
    });

    result?.generated?.forEach((item: { month: number; count: number })  => {
        generatedData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Generated',
                type: "bar",
                data: generatedData,
                borderColor: 'rgb(103, 138, 225)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(1, 71, 234, 0.2)'

            },
            {
                label: 'Approved',
                type: "line",
                data: approvedData,
                borderColor: 'rgb(0, 212, 39)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(0, 234, 0, 0.2)'
            },
            
        ]
    };
}

export const graphOfLeadTimeForMaterialReadiness = (result: { assignedToPOC: { month: number; count: number }[];}) => {
    const assignmentToPOCData = new Array(12).fill(0);
    
    // Populate the data arrays based on the month and count
    result?.assignedToPOC?.forEach((item: { month: number; count: number }) => {
        assignmentToPOCData[item.month - 1] = item.count; // Convert the count to a string and round it to 2 decimal places before adding it to the data array.item.count;
    });
    
    return {
        labels: Months,
        datasets: [
            {
                label: 'Lead Time (Days)',
                fill: true,
                data: assignmentToPOCData,
                borderColor: 'rgb(192, 130, 75)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(192, 141, 75, 0.2)'
            },
        ]
    };
}

