import { Months } from "helpers";


export const graphOfNumberOfAssignedAndClosedMaterialReadinessDocument = (result: { assigned: { month: number; count: number }[]; closed: { month: number; count: number }[]; outstanding: { month: number; count: number }[] }) => {
    const assignedData = new Array(12).fill(0);
    const closedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.assigned?.forEach((item: { month: number; count: number }) => {
        assignedData[item.month - 1] = item.count;
    });

    result?.closed?.forEach((item: { month: number; count: number })  => {
        closedData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Assigned',
                fill: true,
                data: assignedData,
                borderColor: 'rgb(75, 112, 192)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(75, 100, 192, 0.2)'
            },
            {
                label: 'POC Generated',
                fill: true,
                data: closedData,
                borderColor: 'rgb(0, 212, 39)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(0, 234, 0, 0.2)'
            },
        ]
    };
}

export const graphOfNumberOfConsolidatedAndNotConsolidated = (result: { consolidated: { month: number; count: number }[]; notConsolidated: { month: number; count: number }[] }) => {
    const consolidatedData = new Array(12).fill(0);
    const notConsolidatedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.consolidated?.forEach((item: { month: number; count: number }) => {
        consolidatedData[item.month - 1] = item.count;
    });

    result?.notConsolidated?.forEach((item: { month: number; count: number })  => {
        notConsolidatedData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Consolidated',
                type: "bar",
                data: consolidatedData,
                borderColor: 'rgb(75, 91, 192)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(75, 91, 192, 0.2)'
            },
            {
                label: 'Not Consolidated',
                type: "bar",
                data: notConsolidatedData,
                borderColor: 'rgb(192, 137, 75)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(192, 137, 75, 0.2)'
            },
        ]
    };
}