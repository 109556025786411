import { Months } from "helpers";


export const GraphOfClosedOpenAndPendingMaterialReadinessDocument = (result: { pending: { month: number; count: number }[]; open: { month: number; count: number }[]; closed: { month: number; count: number }[]; }) => {
    const pendingData = new Array(12).fill(0);
    const openData = new Array(12).fill(0);
    const closedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.pending?.forEach((item: { month: number; count: number }) => {
        pendingData[item.month - 1] = item.count;
    });

    result?.open?.forEach((item: { month: number; count: number })  => {
        openData[item.month - 1] = item.count;
    });

    result?.closed?.forEach((item: { month: number; count: number })  => {
        closedData[item.month - 1] = item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Open',
                type: "bar",
                data: openData,
                borderColor: 'rgb(225, 186, 103)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(225, 186, 103, 0.2)'
            },
            {
                label: 'Rejected',
                type: "bar",
                data: pendingData,
                borderColor: 'rgb(225, 103, 103)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgb(225, 103, 103, 0.2)'
            },            
            {
                label: 'Approved',
                type: "bar",
                data: closedData,
                borderColor: 'rgb(0, 212, 39)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(0, 212, 39, 0.2)'
            }
        ]
    };
}

export const GraphOfLeadTimeOfMaterialReadinessDocumentForAssignmentToFF = (result: { readinessToAssigned: { month: number; count: number }[]; partiallyCompleted: { month: number; count: number }[]; notCompleted: { month: number; count: number }[] }) => {
    const readinessToAssignedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.readinessToAssigned?.forEach((item: { month: number; count: number }) => {
        readinessToAssignedData[item.month - 1] = (item.count/24).toFixed(2);
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Lead Time (Days)',
                data: readinessToAssignedData,
                borderColor: 'rgb(75, 95, 192)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(75, 95, 192, 0.2)'
            },
        ]
    };
}

export const GraphOfLeadTimeOfPickedMaterialReadinessDocument = (result: { readinessToPicked: { month: number; count: number }[]; partiallyCompleted: { month: number; count: number }[]; notCompleted: { month: number; count: number }[] }) => {
    const readinessToPickedData = new Array(12).fill(0);

    // Populate the data arrays based on the month and count
    result?.readinessToPicked?.forEach((item: { month: number; count: number }) => {
        readinessToPickedData[item.month - 1] = (item.count/24).toFixed(2); // Convert the count to a string and round it to 2 decimal places before adding it to the data array.item.count;
    });

    return {
        labels: Months,
        datasets: [
            {
                label: 'Lead Time (Days)',
                fill: true,
                data: readinessToPickedData,
                borderColor: 'rgb(192, 130, 75)',
                tension: 0.1,
                borderWidth: 1,
                backgroundColor: 'rgba(192, 141, 75, 0.2)'
            },
        ]
    };
}