import { FormEvent, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loading from "../../../assets/images/loading.gif"
import { makeGetRequest, makePostRequest } from "../../../request";
import { useSelector } from "react-redux";
import { customStyles, formatCurrency } from "../../../helpers";
import Modal from "react-modal"
import Layout from "../../Layout";

const CreateConsolidatedDocument =  () => {
    const navigate = useNavigate()
    const user:any = useSelector((state: any) => state.tepngUser.value);
    // const [selectedPoLineItems, setSelectedPoLineItems] = useState<Record <string, any>[]>([])    
    const [formData, setFormData] = useState<Record<string, any>>({
        countryOfSupply: "",
        countryOfOrigin: "",
        modeOfShipping: "SEA",
        destination: "",
        soldTo: "",
        shipTo: "",
        supplier: "",
        formM: "",
        baNumber: "",
        freightForwarderId: "",
        totalUnitPrice: 0,
        sumTotal: 0,
        purchaseOrderItemSupplies: [],
        // currency: ""
    })
    const [errorData, setErrorData] = useState<Record<string, string>>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    // const [formattedPurchaseOrderItems, setFormattedPurchaseOrderItems] = useState([])
    const [isPurchaseOrderItemsPresentForSea, setIsPurchaseOrderItemsPresentForSea] = useState<boolean>()
    const [isPurchaseOrderItemsPresentForAir, setIsPurchaseOrderItemsPresentForAir] = useState<boolean>()
    const [packagesForSea, setPackagesForSea] = useState<Record<string, any>>()
    const [packagesForAir, setPackagesForAir] = useState<Record<string, any>>()    
    const [expectedDataByCurrency, setExpectedDataByCurrency] = useState<Record<string, any>>({})
    const [selectedExpectedDataByCurrency, setSelectedExpectedDataByCurrency] = useState<Record<string, any>>({})
    const [currencyData, setCurrencyData] = useState<Record<string, any>>({
        availableCurrencies: [], // the currenciesByModeOfShipping with data
        selectedCurrency: "",
    })

    const groupPackageDataByCurrency = (data: any) => {
        return data?.flat().reduce((acc: any , curr: any) => {
            const currency = curr.currency; // Get currency from the object
            if (!acc[currency]) {
                acc[currency] = []; // Initialize if not exists
            }
            acc[currency].push(curr); // Add item to the respective currency group
            return acc;
        }, {});
    };

    const getExpectedDataByCurrency = (packagesForSea: any, packagesForAir: any) => {

        // const packagesGroupedByCurrency = groupPackageDataByCurrency(packagesForSea?.concat(packagesForAir))
        const AirPackages = groupPackageDataByCurrency(packagesForAir) // usd, eur
        const SeaPackages = groupPackageDataByCurrency(packagesForSea)
        
        const AirItems = packagesForAir?.map((pack:any) => pack.purchaseOrderItems)
        const SeaItems = packagesForSea?.map((pack:any) => pack.purchaseOrderItemSupplies)

        const packagesItemsGroupedByCurrency = groupPackageDataByCurrency(SeaItems?.concat(AirItems)) // {USD: [...], EUR: [...]}

        const currenciesArray = Object.keys(packagesItemsGroupedByCurrency ?? {}).sort((a: string, b:string) => a.localeCompare(b))  // ['USD', EUR] set key in an array to tab through.
        const currenciesByModeOfShipping = {
            seaMode: Object.keys(groupPackageDataByCurrency(packagesForSea) ?? {}).sort((a: string, b:string) => a.localeCompare(b)),
            airMode: Object.keys(groupPackageDataByCurrency(packagesForAir) ?? {}).sort((a: string, b:string) => a.localeCompare(b))
        }

        let currency: string 
        if (currencyData.selectedCurrency === "" || currencyData.selectedCurrency === undefined) {
            const newCurrency = currenciesByModeOfShipping?.[formData.modeOfShipping === "SEA" ? "seaMode" : "airMode"]?.[0]; // Store the new currency
            currency = newCurrency
        } else {
            if(currenciesByModeOfShipping?.[formData.modeOfShipping === "SEA" ? "seaMode" : "airMode"]?.includes(currencyData.selectedCurrency)) {   
                currency = currencyData.selectedCurrency
            } else {
                currency = currenciesByModeOfShipping?.[formData.modeOfShipping === "SEA" ? "seaMode" : "airMode"]?.[0]
            }
        }   
        
        setCurrencyData({...currencyData, 
            selectedCurrency: currency, 
            // availableCurrencies: currenciesArray,
            availableCurrenciesByModeOfShipping: {
                seaMode: Object.keys(groupPackageDataByCurrency(packagesForSea) ?? {}).sort((a: string, b:string) => a.localeCompare(b)),
                airMode: Object.keys(groupPackageDataByCurrency(packagesForAir) ?? {}).sort((a: string, b:string) => a.localeCompare(b))
            },
        });  
        
        const airFilteredPackagesByCurrency = Object.entries(AirPackages ?? {})
            .filter(([key]) => key === currency)
            .flatMap(([, value]) => value as any[]);
    
        const airPurchaseOrderItemSupplies = airFilteredPackagesByCurrency
            .flatMap(item => item.purchaseOrderItems)
            .filter(Boolean) // Ensure we don't have undefined or null value
    
        const seaFilteredPackagesByCurrency = Object.entries(SeaPackages ?? {})
            .filter(([key]) => key === currency)
            .flatMap(([, value]) => value as any[]);
    
        const seaPurchaseOrderItemSupplies = seaFilteredPackagesByCurrency
            .flatMap(item => item.purchaseOrderItemSupplies)
            .filter(Boolean) // Ensure we don't have undefined or null values

        const selectedExpectedDataByCurrency = {
            ...(currencyData.selectedCurrency && { currency }), // USD, EUR
            ...(airFilteredPackagesByCurrency.length > 0 && {
                airMode: {
                    packageIds: airFilteredPackagesByCurrency.map(item => item.id),
                    packages: airFilteredPackagesByCurrency,
                    purchaseOrderItemSupplies: airPurchaseOrderItemSupplies,
                    totalUnitPrice: airPurchaseOrderItemSupplies.length > 0
                        ? airPurchaseOrderItemSupplies
                            .map(item => item.purchaseOrderItem?.unitPrice || 0) // Handle undefined unitPrice
                            .reduce((total, price) => total + price, 0)
                        : 0,
                    sumTotal: airPurchaseOrderItemSupplies.length > 0
                        ? airPurchaseOrderItemSupplies
                            .map(item => (item.purchaseOrderItem?.unitPrice || 0) * (item.quantity || 0)) // Handle undefined values
                            .reduce((total, value) => total + value, 0)
                        : 0,
                },
            }),
            ...(seaFilteredPackagesByCurrency.length > 0 && {
                seaMode: {
                    packageIds: seaFilteredPackagesByCurrency.map(item => item.id),
                    packages: seaFilteredPackagesByCurrency,
                    purchaseOrderItemSupplies: seaPurchaseOrderItemSupplies,
                    totalUnitPrice: seaPurchaseOrderItemSupplies.length > 0
                        ? seaPurchaseOrderItemSupplies
                            .map(item => item.purchaseOrderItem?.unitPrice || 0) // Handle undefined unitPrice
                            .reduce((total, price) => total + price, 0)
                        : 0,
                    sumTotal: seaPurchaseOrderItemSupplies.length > 0
                        ? seaPurchaseOrderItemSupplies
                            .map(item => (item.purchaseOrderItem?.unitPrice || 0) * (item.quantity || 0)) // Handle undefined values
                            .reduce((total, value) => total + value, 0)
                        : 0,
                },
            }),
        };

        const expectedDataByCurrency = currenciesArray.map(currency => {
            const forAir = Object.entries(AirPackages ?? {})
                .filter(([key]) => key === currency)
                .flatMap(([, value]) => value as any[])

            const forSea = Object.entries(SeaPackages ?? {})
                .filter(([key]) => key === currency)
                .flatMap(([, value]) => value as any[])
            return {
                currency, // USD, EUR
                ...(forAir.length > 0 && {
                    airMode: {
                        packageIds: forAir.map(item => item.id),    
                        packages: forAir,    
                        purchaseOrderItemSupplies: forAir.flatMap(item => item.purchaseOrderItems),    
                        totalUnitPrice: forAir.flatMap(item => item.purchaseOrderItems).length > 0
                            ? forAir.flatMap(item => item.purchaseOrderItems)
                                .map(item => item?.purchaseOrderItem?.unitPrice || 0) // Handle undefined unitPrice
                                .reduce((total, price) => total + price, 0)
                            : 0,
                        sumTotal: forAir.flatMap(item => item.purchaseOrderItems).length > 0
                            ? forAir.flatMap(item => item.purchaseOrderItems)
                                .map(item => (item?.purchaseOrderItem?.unitPrice || 0) * (item?.quantity || 0)) // Handle undefined values
                                .reduce((total, value) => total + value, 0)
                            : 0,
                    },
                }),
                ...(forSea.length > 0 && {
                    seaMode: {
                        packageIds: forSea.map(item => item.id),
                        packages: forSea,
                        purchaseOrderItemSupplies: forSea.flatMap(item => item.purchaseOrderItemSupplies),
                        totalUnitPrice: forSea.flatMap(item => item.purchaseOrderItemSupplies).length > 0
                            ? forSea.flatMap(item => item.purchaseOrderItemSupplies)
                                .map(item => item?.purchaseOrderItem?.unitPrice || 0) // Handle undefined unitPrice
                                .reduce((total, price) => total + price, 0)
                            : 0,
                        sumTotal: forSea.flatMap(item => item.purchaseOrderItemSupplies).length > 0
                            ? forSea.flatMap(item => item.purchaseOrderItemSupplies)
                                .map(item => (item?.purchaseOrderItem?.unitPrice || 0) * (item?.quantity || 0)) // Handle undefined values
                                .reduce((total, value) => total + value, 0)
                            : 0,
                    },
                }),
            }
        });
        
        setSelectedExpectedDataByCurrency(selectedExpectedDataByCurrency)
        setExpectedDataByCurrency(expectedDataByCurrency)
    }

    const getPurchaseOrderItemsFromPackages = () => {
        setIsLoading(true)
        var request: Record<string, any> = {
            what: "getPurchaseOrderItemsFromPackages",
            params: {
                FreightForwarderId: user.id,
                Destination: formData.destination
            }
        };
        
        makeGetRequest(request)
            .then((response: any) => {
                setIsLoading(false)
                const res = response.data.data // returns packing list
                if(!res.length) {
                    toast.warning("No records found for consolidation!")
                    setCurrencyData({...currencyData, selectedCurrency: ""})
                }                

                // ---> GET PACKING LISTS FOR SEA AND AIR
                const PackinglistForSea = res.filter((entry: {packages: { purchaseOrderItemSupplies: { modeOfTransportation: string }[] }[]}) =>
                    entry.packages.some(pkg =>
                        pkg.purchaseOrderItemSupplies.some(supply => supply.modeOfTransportation === "SEA")
                    )
                );
                    
                // ---------------------------------------------------
                // get packing list for those pos with initial mode of transportation as AIR
                const PackinglistForAir = res.filter((packinglist: any) => 
                    packinglist.packages[0].purchaseOrderItemSupplies.some((item: any) => 
                      item.modeOfTransportation === "AIR" && item.modeOfTransportationChangeStatus === null
                    )
                );
                // get packing list for pos with APPROVED mode of transportation change request
                const PackinglistForModeOfChangeRequestAir = res.filter((packinglist: any) => 
                    packinglist.packages[0].purchaseOrderItemSupplies.some((item: any) => 
                      item.modeOfTransportationChangeStatus === "Approved"
                    )
                );

                const BothPackingListForAir = PackinglistForAir.concat(PackinglistForModeOfChangeRequestAir)
                // ---------------------------------------------------

                // const hasSeaShipping = (data: { packages: { purchaseOrderItemSupplies: { modeOfTransportation: string }[] }[] }[]) => {
                //     return data.some(entry =>
                //         entry.packages.some(pkg =>
                //             pkg.purchaseOrderItemSupplies.some(supply => supply.modeOfTransportation === "SEA")
                //         )
                //     );
                // };

                // Initiate all values to be collected.
                let soldToValues: any[] = [];
                let shipToValues: any[] = [];
                let supplierValues: any[] = [];
                let countryOfSupplyValues: any[] = [];
                
                if(formData.modeOfShipping === "SEA") {
                    // Loop through data to retrieve distinct values from each mrd.                     
                    PackinglistForSea.forEach((item: any) => {
                        if (!soldToValues.includes(item.soldTo)) soldToValues.push(item.soldTo);
                        if (!shipToValues.includes(item.shipTo)) shipToValues.push(item.shipTo);
                        if (!supplierValues.includes(item.supplier)) supplierValues.push(item.supplier);
                        if (!countryOfSupplyValues.includes(item.countryOfSupply)) countryOfSupplyValues.push(item.countryOfSupply);
                    });
                }else if(formData.modeOfShipping === "AIR"){
                    BothPackingListForAir.forEach((item: any) => {
                        if (!soldToValues.includes(item.soldTo)) soldToValues.push(item.soldTo);
                        if (!shipToValues.includes(item.shipTo)) shipToValues.push(item.shipTo);
                        if (!supplierValues.includes(item.supplier)) supplierValues.push(item.supplier);
                        if (!countryOfSupplyValues.includes(item.countryOfSupply)) countryOfSupplyValues.push(item.countryOfSupply);
                    });
                }
                
                // ---> GET PACKAGES FROM PACKING LIST
                const packages = res.map((item:any) => item.packages)                
                
                // Assuming the package dimensions is still the same after taking out the AIR items
                const packagesForSea = PackinglistForSea.flatMap((packinglist: { packages: any; }) => packinglist.packages)
                                                        .filter((pck: any) => pck.purchaseOrderItemSupplies.some((item: any) => item.modeOfTransportation === "SEA"))
                                                        //add a new for each value
                                                        .map((item: any) => ({...item, currency: item.purchaseOrderItemSupplies[0].currency}))
                setPackagesForSea(packagesForSea)
                
                // ---> PURCHASE ORDER ITEMS FROM PACKAGES
                const purchaseOrderItemSupplies = packages.flat().flatMap((data:any) => data.purchaseOrderItemSupplies)
                const formattedItems = purchaseOrderItemSupplies.map((item:any) => ({
                    id: item.id,
                    harmonisedSystemCode: item.harmonisedSystemCode,
                    // modeOfTranportationChangeRequestData: item.modeOfTranportationChangeRequestData, // to be included in the request
                    modeOfTransportationChangeId: item.modeOfTransportationChangeId,
                    purchaseOrderNumber: item.purchaseOrderItem.purchaseOrderNumber,
                    purchaseOrderItemNumber: item.purchaseOrderItem.purchaseOrderItemNumber,
                    purchaseOrderItemId: item.purchaseOrderItem.id,
                    materialNumber: item.purchaseOrderItem.materialNumber,
                    materialDescription: item.purchaseOrderItem.materialDescription,
                    unitPrice: item.purchaseOrderItem.unitPrice,
                    quantity: item.quantity,
                    modeOfTransportation: item.modeOfTransportation,
                    modeOfTransportationChange: item.purchaseOrderItem.modeOfTransportationChange,
                    countryOfOrigin: item.countryOfOrigin,
                    currency: item.currency
                }))

                const PurchaseOrderItemsForSea = formattedItems.filter((item:any) => item.modeOfTransportation === "SEA")
                const PurchaseOrderItemsForAir = formattedItems.filter((item:any) => item.modeOfTransportation === "AIR")
                setIsPurchaseOrderItemsPresentForSea(!!PurchaseOrderItemsForSea.length)
                setIsPurchaseOrderItemsPresentForAir(!!PurchaseOrderItemsForAir.length)
                
                // Getting the values for AIR packages to create the corresponding packages
                // For the items that have their default modeOfTransportation as AIR, get the package info from the MRD package                
                // For the items that mode of transportation has been changed, get the package info from the MOT package                
                // if(formData.modeOfShipping === "AIR"){  
                    const packagesForDefaultAir = PackinglistForAir.flatMap((packinglist: { packages: any; }) => packinglist.packages)
                                                    ?.map((data: any) => ({
                                                        length: data?.length,    
                                                        width: data?.width,
                                                        height: data?.height,
                                                        grossWeight: data?.grossWeight,
                                                        purchaseOrderItems: data?.purchaseOrderItemSupplies,
                                                        currency: data?.purchaseOrderItemSupplies[0].currency, // the currency is same through the entire package
                                                        cubicMeter: data?.cubicMeter,
                                                        id: data?.id, //package id
                                                    }))

                    // the packages have to be gotten from the motchange package details
                    const packagesForMOTChangeAir = PackinglistForModeOfChangeRequestAir
                                                        // Get all the packages in the packing lists
                                                        ?.flatMap((packinglist: { packages: any; }) => packinglist.packages)
                                                        // Get all the item supplies in the packages
                                                        ?.flatMap((itemSupplies: { purchaseOrderItemSupplies: any; }) => itemSupplies?.purchaseOrderItemSupplies)
                                                        // Get all the item supplies that have their mode of transportation as AIR
                                                        ?.filter((item: any) => item?.modeOfTransportation === "AIR" && item?.modeOfTransportationChangeStatus === "Approved")
                                                        // ?.filter((poItem: any) => 
                                                        //     poItem.purchaseOrderItem.id === poItem?.purchaseOrderItem?.modeOfTransportationChange?.modeOfTransportationPackageDetails.find((data: any) => data?.packageItemForModeOfTransportation.find((item: any) => item?.purchaseOrderItemRequestId === poItem?.id))?.packageItemForModeOfTransportation.find((item: any) => item?.purchaseOrderItemRequestId === poItem?.id)?.id)
                                                        // filter the mot package that contains the AIR items
                                                        ?.flatMap((poItem: any) => 
                                                            { 
                                                                
                                                                const modeOfTransportationPackageDetails = poItem?.purchaseOrderItem?.modeOfTransportationChange?.modeOfTransportationPackageDetails
                                                                const purchaseOrderItemRequestId = poItem.purchaseOrderItem.id

                                                                const motPackages = modeOfTransportationPackageDetails.flat()
                                                                
                                                                const packageWithTheCurrentItem = motPackages
                                                                        .filter((data: any) => 
                                                                            data?.packageItemForModeOfTransportation.find((item: any) => item?.purchaseOrderItemRequestId === purchaseOrderItemRequestId)
                                                                        )
                                                                                                              
                                                                return packageWithTheCurrentItem
                                                            }
                                                        )   
                                                        // Format the data for the package information display                                                   
                                                        ?.map((data: any) => ({
                                                            length: data?.estimatedDimension?.split("x")[0].trim(),    
                                                            width: data?.estimatedDimension?.split("x")[1].trim(),
                                                            height: data?.estimatedDimension?.split("x")[2].trim(),
                                                            grossWeight: data?.estimatedWeight,
                                                            purchaseOrderItems: data?.packageItemForModeOfTransportation,
                                                            currency: data?.packageItemForModeOfTransportation[0].currency, // the currency is same through the entire package
                                                            cubicMeter: data?.cubicMeters, 
                                                            id: data?.modeOfTransportationId, //motId
                                                        }))

                    const BothPackagesForAir = packagesForDefaultAir.concat(packagesForMOTChangeAir)
                    // Format the data to be displayed
                    setFormData({...formData,
                        soldTo: soldToValues.join(";"),
                        shipTo: shipToValues.join(";"),
                        supplier: supplierValues.join(";"),
                        countryOfOrigin: 
                        formData.modeOfShipping === "SEA" 
                            ? Array.from(new Set(PurchaseOrderItemsForSea.map((item: any) => item.countryOfOrigin))).join(", ") 
                            : formData.modeOfShipping === "AIR" 
                            ? Array.from(new Set(PurchaseOrderItemsForAir.map((item: any) => item.countryOfOrigin))).join(", ") 
                            : "",
                        countryOfSupply: countryOfSupplyValues.join(", "),
                        ...((BothPackagesForAir?.length === 0 && packagesForSea?.length > 0) && {modeOfShipping: "SEA"}),
                        // purchaseOrderItemSupplies: formData.modeOfShipping === "SEA" ? PurchaseOrderItemsForSea.map((item:any) => item.id) : PurchaseOrderItemsForAir.map((item:any) => item.id)
                    })
                    
                    setPackagesForAir(BothPackagesForAir)                    
                    getExpectedDataByCurrency(packagesForAir, BothPackagesForAir)

                    // const MOTs = PurchaseOrderItemsForAir?.map((item: any) => ({
                    //     modeOfTransportation: item.modeOfTransportationChange,
                    //     purchaseOrderItemId: item.purchaseOrderItemId,
                    // }))                                     

                    // // Group the MOTs by modeOfTransportationId
                    // const groupedData = PurchaseOrderItemsForAir.reduce((acc: any, obj: any) => {
                    //     const key = obj.id;
                    //     if (!acc[key]) {
                    //         acc[key] = [];
                    //     }
                    //     acc[key].push(obj);
                    //     return acc;
                    // }, {});

                    // Format the MOTs to imitate packages
                    // const formattedMots: any = [];
                    // const seenIds = new Set();
                    // MOTs.forEach((res: any) => {
                        
                    //     const modeOfTransportationChange = res.modeOfTransportation
                    //     if (seenIds.has(modeOfTransportationChange.id)) return;
                    //     seenIds.add(modeOfTransportationChange.id);                        
                    //     const dimensions = modeOfTransportationChange.estimatedDimensions ? modeOfTransportationChange.estimatedDimensions.split("x") : [] // length x width x height
                    //     const isDimensionsAvailable = dimensions.length > 0
                    //     formattedMots.push({
                    //         length: isDimensionsAvailable ? dimensions[0].trim() : 0,
                    //         width: isDimensionsAvailable ? dimensions[1].trim() : 0,
                    //         height: isDimensionsAvailable ? dimensions[2].trim() : 0,
                    //         grossWeight: modeOfTransportationChange.estimatedWeight,
                    //         purchaseOrderItemIds: groupedData[modeOfTransportationChange.id].map((item: any) => item.purchaseOrderItemId),
                    //         // packingListId: modeOfTransportation.packingListId,
                    //         cubicMeter: 0, // change this
                    //         id: modeOfTransportationChange.id,
                    //     });
                        
                    // });
                    // setPackagesForAir(formattedMots)
                // }
            })
            .catch((error:any) => 
                toast.error(error)
            );
    }

    const handleInputChange = (e: any) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }

    const [itemSuppliesIdsFromNewPackages, setItemSuppliesIdsFromNewPackages] = useState<Record<string, any>>([])
    // const [newPackagesIdForAir, setNewPackagesIdForAir] = useState([])
    const getPackages = () => {
        var request: Record<string, any> = {
            what: "getPackageForFreightForwader",
            id: user.id
        };
        
        makeGetRequest(request)
            .then((response: any) => {
                const res = response.data.data
                const purchaseOrderItemIdsFromNewPackages: any = []

                res.forEach((item: any) => {
                    purchaseOrderItemIdsFromNewPackages.push(item.purchaseOrderItemSupplies.map((data: any) => data.id))            
                })
                setItemSuppliesIdsFromNewPackages(purchaseOrderItemIdsFromNewPackages)
                // setNewPackagesIdForAir(res.map((item: any) => item.id))
            })
            .catch((error:any) => 
                toast.error(error)
            );
    }

    const HandleCreatePackage = (event: any, packageData: any) => {
        event.target.disabled = true   
        setIsLoading(true)
        const data = {
            purchaseOrderItems: packageData?.purchaseOrderItems.map((item: {id: string, harmonisedSystemCode: string}) => ({
                    id: item.id,
                    harmonisedSystemCode: item.harmonisedSystemCode,
                })),
            length: packageData.length,
            width: packageData.width,
            height: packageData.height,
            packageNumber: "1",
            grossWeight: packageData.grossWeight,
            cubicMeter: packageData.cubicMeter,
            userId: user.id,
            destination: formData.destination
        };

        var request:Record<string, any> = {
            what: "CreatePackageForAirConsolidation",
            data: data
        }
        makePostRequest(request)
            .then((response: any) => {
                setIsLoading(false)
                event.target.disabled = false 
                toast.success(response.msg)
                getPackages()

            }).catch((error:any) => {toast.error(error.msg); setIsLoading(false)});
    }
    
    const HandleCreateConsolidatedDocument = (e: FormEvent) => {
        e.preventDefault()
        if(!formData.formM || formData.baNumber!) setFormData({...formData, modeOfShipping: "AIR"})
        const data: any = {
            countryOfSupply: formData.countryOfSupply,
            countryOfOrigin: formData.countryOfOrigin,
            destination: formData.destination,
            soldTo: formData.soldTo,
            shipTo: formData.shipTo,
            supplier: formData.supplier,
            freightForwarderId: user.id,
            consolidationByCurrencies: expectedDataByCurrency.map((data: any) => ({
                currency: data.currency,
                ...(data.seaMode && {seaMode: {
                    packageIds: data.seaMode.packageIds,
                    purchaseOrderItemSupplies: data.seaMode.purchaseOrderItemSupplies.map((item: any) => item.id),
                    totalUnitPrice: data.seaMode.totalUnitPrice,
                    sumTotal: data.seaMode.sumTotal
                }}),
                ...(data.airMode && {airMode: {
                    formM: formData.formM,
                    baNumber: formData.baNumber,
                    packageIds: data.airMode.packageIds,
                    purchaseOrderItemSupplies: data.airMode.purchaseOrderItemSupplies.map((item: any) => item.id),
                    totalUnitPrice: data.airMode.totalUnitPrice,
                    sumTotal: data.airMode.sumTotal
                }})                
            })),
        }

        if (Object.values(data).every(value => value) ){
            setIsSubmitting(true)
            var request:Record<string, any> = {
                what: "CreateConsolidatedDocuments",
                data: data
            };      

            makePostRequest(request)
                .then(() => {   
                    navigate("/freightforwarder/consolidateddocuments")                    
                })
                .catch((error:any) => {toast.error(error.msg); setIsSubmitting(false)});          
        }else toast.warning("Please provide information for all required fields")
    }

    const [destinations, setDestinations] = useState([])
    const getAllFinalShippingAddress = () => {
        var request: Record<string, any> = {
            what: "getAllFinalShippingAddress",
        };
        
        makeGetRequest(request)
            .then((response: any) => {
                const res = response.data.data
                setDestinations(res)
            })
            .catch((error:any) => 
                console.log(error.errorMessage)
            );
    }

    useEffect(() => {
        if(formData.destination) getPurchaseOrderItemsFromPackages() 
        //eslint-disable-next-line
    }, [formData.modeOfShipping, formData.destination])

    useEffect(() => {
        getExpectedDataByCurrency(packagesForSea, packagesForAir)
        //eslint-disable-next-line
    }, [currencyData.selectedCurrency, packagesForSea, packagesForAir])

    useEffect(() => {
        getPackages() //eslint-disable-next-line
    }, [formData.modeOfShipping === "AIR"])

    useEffect(() => {
       getAllFinalShippingAddress()        
    }, [])

    useEffect(() => {
        if(packagesForSea?.length === 0 && packagesForAir?.length > 0) setFormData({...formData, modeOfShipping: "AIR"})        
        //eslint-disable-next-line
    }, [packagesForAir])

    const page = "Consolidated Documents"

    return (
        <Layout title={page}>
            <div className="container" >
                <div className="main">
                    <h3 className="page_title">{  }</h3>     
                    
                    <div className="main-inner">                  
                        <div className="detail-top-section">
                            <div onClick={() => navigate("/freightforwarder/consolidateddocuments")} style={{width:"fit-content"}}>
                                <p><span className="material-symbols-rounded">arrow_back</span> Back to Consolidated Documents</p>
                            </div>
                              
                            <div className="tab">
                                <div className="tab-item active">
                                    <span className="material-symbols-rounded">receipt_long</span>
                                    <p>Create Consolidated Documents</p>
                                </div>
                            </div>                      
                        </div>                         
                    </div>
                    <div className="main-inner mt-1" style={{minHeight: "100vh"}}>   
                        <div className="form-view-container custom" style={{padding: "0px 4px"}}>
                            <div className="layout" style={{paddingTop: "16px"}}>
                                <div className="label">Select</div>
                                <div className="body d-grid-3 custom">  
                                    <div className='form-item span-col-1'>
                                        <label><span className="errorX mr-2">*</span> Destination </label>
                                        <select name="destination" value={formData.destination} onChange={handleInputChange} required>
                                            <option value="" disabled></option>
                                            {
                                                destinations.map((destination: {address: string}, index: number) => {
                                                    return (
                                                        <option key={index} value={destination.address}>{ destination.address }</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <p className="error">{ errorData?.destination }</p>
                                    </div>

                                    <div className='form-item span-col-1'>
                                        <label><span className="errorX mr-2">*</span> Mode of Shipping</label>
                                        <select name="modeOfShipping" 
                                            value={formData.modeOfShipping} 
                                            onChange={handleInputChange} 
                                            disabled={!isPurchaseOrderItemsPresentForSea && !isPurchaseOrderItemsPresentForAir}
                                            required>
                                            <option value="SEA" disabled={!isPurchaseOrderItemsPresentForSea} title={isPurchaseOrderItemsPresentForSea ? "" : "No Data for Sea Mode of Shipping"}>SEA</option>
                                            <option value="AIR" disabled={!isPurchaseOrderItemsPresentForAir} title={isPurchaseOrderItemsPresentForAir ? "" : "No Data for Air Mode of Shipping"}>AIR</option>
                                        </select>
                                        <p className="error"></p>
                                    </div>

                                    <div className='form-item span-col-1'>
                                        <label><span className="errorX mr-2">*</span> Currency</label>
                                        <select 
                                            name="selectedCurrency" 
                                            value={currencyData?.selectedCurrency} 
                                            onChange={(e) => setCurrencyData({...currencyData, selectedCurrency: e.target.value})} 
                                            disabled={!isPurchaseOrderItemsPresentForSea && !isPurchaseOrderItemsPresentForAir}
                                            required>
                                                {
                                                    currencyData?.availableCurrenciesByModeOfShipping?.[formData.modeOfShipping === "SEA" ? "seaMode" : "airMode"]?.map((currency: string, index: number) => {
                                                        return (
                                                            <option key={index} value={currency} disabled={Object.keys(selectedExpectedDataByCurrency).includes(currency)}>{ currency }</option>
                                                        )
                                                    })
                                                }
                                        </select>
                                        <p className="error"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {expectedDataByCurrency.length > 0 && <form onSubmit={HandleCreateConsolidatedDocument} >                            
                            {isPurchaseOrderItemsPresentForAir && 
                            <div className="alert alert-info" style={{margin: "0 12px 12px 12px", width: "auto",padding:"8px"}}>
                                <span className="material-symbols-outlined mr-2 f-16">info</span>
                                <p style={{margin: 0}}>Select "AIR" mode of shipping, create all the necessary packages and fill in the required fields.</p>
                            </div>} 

                            {(formData.modeOfShipping === "AIR" ? packagesForAir?.length > 0 : packagesForSea?.length > 0) 
                            && <div style={{margin: "0 12px"}}>  
                                <p style={{ fontSize: "11px", fontWeight: "500", backgroundColor: "#175FDC", color: "white", padding: "8px", textAlign: "center", borderRadius: "4px"}}
                                >Packages for {formData.modeOfShipping} Consolidation</p>         
                                <div className='table-container custom' style={{minHeight: "fit-content"}}>
                                    <table>
                                        <thead>
                                            <tr className="no-textwrap">
                                                <th>Package</th>
                                                <th className="text-center">Length (CM)</th>
                                                <th className="text-center">Width (CM)</th>
                                                <th className="text-center">Height (CM)</th>
                                                <th className="text-center">Cubic Meter (M<sup>3</sup>)</th>
                                                <th className="text-center">Gross Weight (KG)</th>
                                                {formData.modeOfShipping === "AIR" && <th>Action</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                selectedExpectedDataByCurrency
                                                ?.[formData.modeOfShipping === "SEA" ? "seaMode" : "airMode"]
                                                ?.packages
                                                ?.map((data:any, i:number) => {
                                                    return (
                                                        <tr key={i} >
                                                            <td>Package {i+1}</td>
                                                            <td className="text-center">{data.length}</td>
                                                            <td className="text-center">{data.width}</td>
                                                            <td className="text-center">{data.height}</td>
                                                            <td className="text-center">{data.cubicMeter}</td>                                                        
                                                            <td className="text-center">{data.grossWeight}</td>
                                                            {formData.modeOfShipping === "AIR" && <td>
                                                                {/* Disable button if the package hasn't already been created */}
                                                                {/* The current package items is compared against the items from the newly created packages. This is becuase a new package id is generated so the only means of comparism is the package items. */}
                                                                {<button 
                                                                    disabled={(data.purchaseOrderItems?.map((item: any) => item.id))
                                                                        ?.every((item: any) => (itemSuppliesIdsFromNewPackages).some((subArr: any) => subArr.includes(item)))}
                                                                    type="button" 
                                                                            className="custom-button blue-outline" 
                                                                            onClick={(event) => HandleCreatePackage(event, data)}>Create Package</button>}
                                                            </td>}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div> }

                            {Object.keys(selectedExpectedDataByCurrency ?? {}).length !== 0 && 
                            <div style={{margin: "0 12px"}}>                 
                                <p style={{fontSize: "11px", fontWeight: "500", backgroundColor: "#175FDC", color: "white", padding: "8px", textAlign: "center", borderRadius: "4px"}}
                                >Purchase Order Items for {formData.modeOfShipping} Consolidation</p> 
                                <div className='table-container custom' style={{minHeight: "fit-content"}}>
                                    <table>
                                        <thead>
                                            <tr className="no-textwrap">
                                                <th>SN</th>
                                                <th>Purchase Order Number</th>
                                                <th>Material Number</th>
                                                <th>Item Number</th>
                                                <th>Material Description</th>
                                                <th>HS Code</th>
                                                <th>Quantity</th>                                            
                                                <th>Unit Price ({currencyData?.selectedCurrency})</th>
                                                <th>Total ({currencyData?.selectedCurrency})<small className="info">*Calculated</small></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                selectedExpectedDataByCurrency
                                                ?.[formData.modeOfShipping === "SEA" ? "seaMode" : "airMode"]
                                                ?.purchaseOrderItemSupplies                                                
                                                ?.map((data: any, i: number) => {
                                                    return (
                                                        <tr key={i} >
                                                            <td>{i+1}</td>
                                                            <td>{data.purchaseOrderItem.purchaseOrderNumber}</td>
                                                            <td>{data.purchaseOrderItem.materialNumber}</td>
                                                            <td>Item {data.purchaseOrderItem.purchaseOrderItemNumber}</td>                                                            
                                                            <td>{data.purchaseOrderItem.materialDescription}</td>
                                                            <td>{data.harmonisedSystemCode}</td>
                                                            <td>{data.quantity}</td>
                                                            <td>{formatCurrency(data.purchaseOrderItem.unitPrice)}</td>
                                                            <td>{formatCurrency(Number(data.purchaseOrderItem.unitPrice) * Number(data.quantity))}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tbody>
                                            <tr style={{background: "transparent"}}>
                                                <td colSpan={6}></td>
                                                <td><strong>Total:</strong></td>
                                                <td><strong>{formatCurrency(selectedExpectedDataByCurrency?.[formData.modeOfShipping === "SEA" ? "seaMode" : "airMode"]?.totalUnitPrice)}</strong></td>
                                                <td><strong>{formatCurrency(selectedExpectedDataByCurrency?.[formData.modeOfShipping === "SEA" ? "seaMode" : "airMode"]?.sumTotal)}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>}

                            <div className="form-view-container custom" style={{padding: "16px 4px", borderTop: "1px solid #ccc"}} >
                                <div className="layout">
                                    <div className="label">Summary Details</div>
                                    <div className="body d-grid custom">  
                                        {formData.modeOfShipping === "AIR" && <>
                                            <div className='form-item span-col-2'>
                                                <label>Form M <small className="text-red">(Required)</small></label>
                                                <input required name="formM" value={formData?.formM} onChange={handleInputChange} type='text'
                                                    onKeyUp={() => {formData.formM.length < 1 && formData.modeOfShipping === "AIR" ? setErrorData({ ...errorData, formM: 'This field is required' }) : 
                                                    setErrorData({ ...errorData, formM: '' })}} />
                                                <p className="error">{ errorData?.formM }</p>
                                            </div> 

                                            <div className='form-item span-col-2'>
                                                <label>BA Number <small className="text-red">(Required)</small></label>
                                                <input required name="baNumber" value={formData?.baNumber} onChange={handleInputChange} type='text'
                                                    onKeyUp={() => {formData.baNumber.length < 1 && formData.modeOfShipping === "AIR" ? setErrorData({ ...errorData, baNumber: 'This field is required' }) : 
                                                    setErrorData({ ...errorData, baNumber: '' })}} />
                                                <p className="error">{ errorData?.baNumber }</p>
                                            </div> 
                                        </>}  
                                        <div className='form-item span-col-4'>
                                            <label>Sold To</label>
                                            <textarea className="mt-1 custom-input" rows={3} disabled value={formData.soldTo}></textarea>
                                            <p className="error">{ errorData?.soldTo }</p>
                                        </div>
                                        <div className='form-item span-col-4'>
                                            <label>Ship To</label>
                                            <textarea className="mt-1 custom-input" rows={3} disabled value={formData.shipTo}></textarea>
                                            <p className="error">{ errorData?.shipTo }</p>
                                        </div>
                                        <div className='form-item span-col-4'>
                                            <label>Suppliers</label>
                                            <textarea className="mt-1 custom-input" rows={3} disabled value={formData.supplier}></textarea>
                                            <p className="error">{ errorData?.supplier }</p>
                                        </div>

                                        <div className='form-item span-col-4'>
                                            <label>Country of Origin</label>
                                            <textarea className="mt-1 custom-input" rows={3} disabled value={formData.countryOfOrigin}></textarea>
                                            <p className="error">{ errorData?.countryOfOrigin }</p>
                                        </div>   
                                        <div className='form-item span-col-4'>
                                            <label>Country of Supply</label>
                                            <input type="text" disabled value={formData.countryOfSupply} />
                                            <p className="error">{ errorData?.countryOfSupply }</p>
                                        </div>                                      
                                    </div>                                          
                                </div>
                            </div> 

                            <div style={{padding: "12px", borderTop: "1px solid #ccc",}}>
                                <button 
                                    type="submit" 
                                    className="custom-button orange d-flex-center"
                                    style={{margin: "0 auto", width: "100%"}}
                                    disabled={(
                                            (!formData.formM || !formData.baNumber) && packagesForAir?.length) 
                                            || isSubmitting 
                                            //check the value is empty
                                            || Object.keys(selectedExpectedDataByCurrency ?? {}).length === 0
                                            || !(packagesForAir?.flatMap((item: any) => item.purchaseOrderItems?.map((item: any) => item.id))
                                            ?.every((item: any) => (itemSuppliesIdsFromNewPackages).some((subArr: any) => subArr.includes(item))))
                                        }
                                    >
                                    <span className="material-symbols-rounded">web_traffic</span> {isSubmitting ? "Loading..." : "Create Consolidated Documents"}
                                </button>
                            </div>                            
                        </form>}   
                    </div>     
                </div>
                <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                    <div className="loader">
                        <img src={loading} alt="loading" />
                        <p>Loading data...</p>
                    </div>
                </Modal> 
                <ToastContainer />
            </div>            
        </Layout>
    )
}

export default CreateConsolidatedDocument