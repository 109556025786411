import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal"
import Layout from "../../Layout";
import { makeGetRequest, makePatchRequest } from "request";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { customStyles } from "helpers";
import loading from "../../../assets/images/loading.gif"


const ManageReceptionDetails =  () => {
    const navigate = useNavigate()  
    const param = useParams()  
    const location = useLocation()
    const statusAfterNavigation = location.state as { status: number };
    const [purchaseOrderItems, setPurchaseOrderItems] = useState<Record<string, any>[]>([])
    const [shippingDocument, setShippingDocument] = useState<Record<string, any>>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    // const [isChecked, setIsChecked] = useState(false)

    const [formData, setFormData] = useState({
        shippingDocumentId: "",
        dateOfReceipt: "",    
        dateOfTransferToReception: "",
        handOffDocumentId: "",
        purchaseOrderItemSuppliesDeliveryInformation: [] as {
            purchaseOrderItemSupplyId: string;
            isDelivered: boolean;
            deliveredQuantity: number;
            undeliveredQuantity: number
          }[],
        rowVersion: ""
    })
    
    const clearData = () => {
        setFormData({
            shippingDocumentId: "",
            dateOfReceipt: "",    
            dateOfTransferToReception: "",
            handOffDocumentId: "",
            purchaseOrderItemSuppliesDeliveryInformation: [],
            rowVersion: "",
        })
    }

    const handleInputChange = (e: any) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, supplyId: string,) => {
        const { checked } = e.target;
        setPurchaseOrderItems((prevItems) =>
            prevItems.map((item: any) => {
                if (item.id === supplyId) {
                    return {
                        ...item,
                        isChecked: checked,
                        deliveredQuantity: 0,
                    };
                }
                return item;
            })
        );
        
    };
    
    const handleQtyChange = (supplyId: string, quantity: number) => {
        setPurchaseOrderItems((prevItems) =>
            prevItems.map((item: any) => {
                if (item.id === supplyId) {
                    return {
                        ...item,
                        deliveredQuantity: (quantity < 0 || item.quantity < quantity) ? 0 : quantity,
                        undeliveredQuantity: quantity > item.quantity ? item.quantity : item.quantity - quantity
                    };
                }
                return item;
            })
        );
    };
    
    const getConsolidatedDocuments = () => {
        setIsLoading(true)
        var request: Record<string, any> = {
            what: "getShippingDocumentById",
            id: param.id
        };
        
        makeGetRequest(request)
            .then((response: any) => {
                setIsLoading(false)
                const res = response.data.data
                setShippingDocument(res)
                setPurchaseOrderItems(res.consolidatedPackingList?.packages
                    ?.map((item: any) => item.purchaseOrderItemSupplies)
                    .flat())
            })
            .catch((error:any) => {
                toast.error(error.msg)
                setIsLoading(false)
            });
    }

    const HandleSetReceptionDetails = (event: FormEvent) => {
        event.preventDefault()        
        if(purchaseOrderItems.filter((item: any) => item.isChecked).length === 0) {
            toast.warning("Please select at least one item to proceed.")
            return
        }
        // confirm that all the checked purchaseorderitems have delivery quantity
        if(purchaseOrderItems.filter((item: any) => item.isChecked && item.deliveredQuantity === 0).length > 0) {
            toast.warning("Please enter a quantity greater than zero for the selected item(s) else uncheck them to proceed.")
            return 
        }

        setIsSubmitting(true)
        var request: Record<string, any> = {
            what: "SetPortOfficerDates",
            data: {
                shippingDocumentId: param.id,
                dateOfReceipt: formData?.dateOfReceipt,
                dateOfTransferToReception: formData?.dateOfTransferToReception,
                handOffDocumentId: shippingDocument?.handOffDocumentId,
                rowVersion: shippingDocument?.rowVersion,
                purchaseOrderItemSuppliesDeliveryInformation: purchaseOrderItems.filter((item: any) => item.isChecked).map((item: any) => ({
                    purchaseOrderItemSupplyId: item.id,
                    isDelivered: true,
                    deliveredQuantity: item.deliveredQuantity,
                    undeliveredQuantity: item.undeliveredQuantity
                })),
            }
        };
        
        makePatchRequest(request)
            .then((response: any) => {
                toast.success(response.msg+ ". You'll be navigated shortly to the generated handoff document.")
                setIsSubmitting(false)
                clearData()
                setTimeout(() => {
                    navigate("/portofficer/handoffdocument/"+param.id)
                }, 1000);                             
            })
            .catch((error:any) => 
                {toast.error(error.msg); setIsSubmitting(false)}
            );
    }

    useEffect(() => {
        getConsolidatedDocuments() // eslint-disable-next-line
    }, [])

    const page = "Shipping Documents"

    return (
        <Layout title={page}>
            <div className="container">
                <div className="main">
                    <div className="main-inner mt-2">                  
                        <div className="detail-top-section">
                            <div onClick={() => navigate("/portofficer/shippingdocuments", {state: {status: statusAfterNavigation?.status}})} className="actions">
                                <p><span className="material-symbols-rounded">arrow_back</span> Back to Shipping Documents</p>
                            </div>
                              
                            <div className="tab">
                                <div className="tab-item active">
                                    <span className="material-symbols-rounded">receipt_long</span>
                                    <p>Set Reception Details</p>
                                </div>    
                            </div>                     
                        </div>
                    </div>
                     
                    <form onSubmit={HandleSetReceptionDetails} className="main-inner mt-1" style={{minHeight:"450px"}}>                 
                        <div className="form-view-container custom" style={{padding: "0px 4px"}}>
                            <div className="layout" style={{paddingTop: "16px"}}>
                                <div className="label">Select</div>
                                <div className="body d-grid-2 custom">  
                                    <div className='form-item span-col-1'>
                                        <label><span className="errorX mr-2">*</span> Date of Receipt </label>
                                        <input required style={{marginBottom: "8px"}} name="dateOfReceipt" type='date' value={formData?.dateOfReceipt} onChange={handleInputChange} />                        
                                        <p className="error"></p>
                                    </div>   
                                    <div className='form-item span-col-1'>
                                        <label><span className="errorX mr-2">*</span> Date of Transfer to Reception </label>
                                        <input required style={{marginBottom: "8px"}} name="dateOfTransferToReception" type='date' value={formData?.dateOfTransferToReception} onChange={handleInputChange} />                        
                                        <p className="error"></p>
                                    </div>                   
                                </div>                   
                            </div>  
                            <div>  
                                <p style={{ fontSize: "11px", fontWeight: "500", backgroundColor: "#175FDC", color: "white", padding: "8px", textAlign: "center", borderRadius: "4px"}}
                                >Purchase Order Items</p>         
                                {purchaseOrderItems.length > 0 && <div className="alert alert-info" style={{padding: "8px", width: "auto"}}>
                                    <span className="material-symbols-outlined mr-2" style={{color: "#004085", fontSize: "16px"}}>info</span>
                                    <p style={{margin: 0, fontSize: "11px"}}>The delivered quantity cannot exceed the supplied quantity. If it does, the input field will automatically reset to zero.</p>
                                </div>}
                                <div className='table-container custom' style={{minHeight: "fit-content"}}>
                                    <table>
                                        <thead>
                                            <tr className="no-textwrap">
                                                <th></th>
                                                <th>SN</th>
                                                <th>Purchase Order Number</th>
                                                <th>Material Number</th>
                                                <th>Item Number</th>
                                                <th>Material Description</th>
                                                <th>Supplied Qty</th>                                            
                                                <th>Delivered Qty</th>
                                                <th>Undelivered Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                purchaseOrderItems
                                                ?.map((data: any, i: number) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>
                                                                <input type="checkbox" onChange={(event) => handleCheck(event, data.id)} />
                                                            </td>
                                                            <td>{i+1}</td>
                                                            <td>{data.purchaseOrderItem.purchaseOrderNumber}</td>
                                                            <td>{data.purchaseOrderItem.materialNumber}</td>
                                                            <td>Item {data.purchaseOrderItem.purchaseOrderItemNumber}</td>                                                            
                                                            <td>{data.purchaseOrderItem.materialDescription}</td>
                                                            <td>{data.quantity}</td>
                                                            <td>
                                                                <input 
                                                                    className="custom-input" 
                                                                    style={{width: "40px"}}
                                                                    type="number"
                                                                    disabled={!data.isChecked} 
                                                                    value={data.deliveredQuantity} 
                                                                    onChange={(event) => handleQtyChange(data.id, Number(event.target.value))} />
                                                            </td>
                                                            <td>{data.undeliveredQuantity}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>                   
                            </div>

                            <div style={{marginTop: "16px", padding: "12px 0", borderTop: "1px solid #ccc",}}>
                                <button 
                                    type="submit" 
                                    className="custom-button orange d-flex-center"
                                    style={{width: "100%"}}
                                    disabled={purchaseOrderItems.length === 0 || isSubmitting}>
                                    <span className="material-symbols-rounded">web_traffic</span> {isSubmitting ? "Loading..." : "Submit"}
                                </button>
                            </div>                
                        </div>   
                    </form>
                </div>
                <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                    <div className="loader">
                        <img src={loading} alt="loading" />
                        <p>Loading data...</p>
                    </div>
                </Modal>   
               <ToastContainer />
            </div>
        </Layout>
    )
}

export default ManageReceptionDetails