import html3pdf from 'html3pdf';
import { currentDatetime, formatDateTime } from '../../helpers';
import logo from '../../assets/images/logo-1.png'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeGetRequest, makePatchRequest } from 'request';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PdfGenerator = (props) => {
  const {shippingDocument} = props
  const user = useSelector(state => state.tepngUser.value);
  const location = useLocation()
  const navigate = useNavigate()
  const currentRolePage = location?.pathname.split("/")[1]
  const referenceNumber = shippingDocument?.referenceNumber
  const localClearingAgent = shippingDocument?.localClearingAgent
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isChecked, setIsChecked] = useState(false)  
  const [handOffDocument, setHandOffDocument] = useState({})  

  const getHandOffDocumentById = () => {
      // setIsLoading(true)
      var request = {
        what: "getHandOffDocumentById",
        params: {
          handOffDocumentId: shippingDocument?.handOffDocumentId
        }
      };
      
      makeGetRequest(request)
        .then(response => {
          // setIsLoading(false)
          const res = response.data.data
          setHandOffDocument(res)
        })
        .catch(error => 
          toast.error(error.errorMessage)
        );
  }
  
  const packingList = shippingDocument?.consolidatedPackingList?.packages?.map((data) => { 
    return data.purchaseOrderItemSupplies.map((item) => {
      return ({
        purchaseOrderItemSuppliesId: item.id,
        supplier: data.user?.companyName
      })
    })    
  }).flat()
  
  const purchaseOrderItems = shippingDocument?.consolidatedPackingList?.packages
                            ?.map((item) => item.purchaseOrderItemSupplies)
                            .flat()
                            ?.map((item) => { return ({
    item: item.purchaseOrderItem,
    suppliedQty: item.quantity,
    quantityDelivered: item.quantityDelivered,
    undeliveredQuantity: item.undeliveredQuantity,
    purchaseOrderItemQty: item.purchaseOrderItem.quantity,
    purchaseOrderNumber: item.purchaseOrderItem.purchaseOrderNumber,
    harmonisedSystemCode: item.harmonisedSystemCode,
    countryOfOrigin: item.countryOfOrigin,
    currency: item.currency,
    supplier: packingList.find((data) => data.purchaseOrderItemSuppliesId === item.id)?.supplier
  }) })

  const handleHandoffDocumentAcknowledgement = () => {
    setIsSubmitting(true)
    var request = {
        what: "ValidateHandOffDocument",
        data: {
            handOffDocumentId: handOffDocument?.id,
            isSuccess: true,
            rowVersion: shippingDocument.rowVersion,
            portOfficerId: user?.id
        },
    };
    
    makePatchRequest(request)
        .then((response) => {
            setIsSubmitting(false)
            toast.success(response.msg)

            setTimeout(() => {
                navigate("/portofficer/shippingdocuments")
            }, 1000);
        })
        .catch((error) => 
            {toast.error(error.msg); setIsSubmitting(false)}
        );
  }

  useEffect(() => {
    getHandOffDocumentById() //eslint-disable-next-line
  }, [])

  const generatePdf = () => {
    const pdfContent = document.getElementById('pdf-content');

    html3pdf(pdfContent, {
      margin: 10,
      filename: `HandOffDocument-${referenceNumber ? referenceNumber : ""}-${currentDatetime.substring(0, 10)}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html3canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'LANDSCAPE' },
    });
  };

  return (
    <div style={{padding: "16px 0"}}>
      {/* Button to generate PDF */}
      <div className='actions blue' style={{marginLeft: "16px", width: "fit-content"}} onClick={generatePdf}>
        <span className="material-symbols-rounded">download</span>
        <span>Download PDF</span>
      </div>
      <hr style={{border: "1px solid #e9e9e9", marginTop: "16px"}} />

      {/*  div with content for PDF generation */}
      <div id="pdf-content" className='pdf-content' style={{padding: "0 24px"}}>
        <img src={logo} alt='' width="80px" />
        <h6 className='m-0'>Total E & P Nigeria Limited</h6>
        <h6 className='m-0'>Procurement & Contracts Department</h6>

        <h5 className='text-center'>MATERIAL DELIVERY WAYBILL</h5>

        <table className='template-form'>
          <tbody>
            <tr>
              <td>DATE OF RECEIPT</td>
              <td className='highlight'>{formatDateTime(shippingDocument?.dateOfReceipt)?.substring(0, 10)}</td>
              <td>DATE OF TRANSFER TO RECEPTION</td>
              <td className='highlight'>{formatDateTime(shippingDocument?.dateOfTransferToReception)?.substring(0, 10)}</td>
            </tr>
            <tr>
              <td>CLEARING AGENT</td>
              <td className='highlight'>{localClearingAgent ? localClearingAgent?.firstName : null} {localClearingAgent ? localClearingAgent?.lastName : null}</td>
              <td>CLEARING AGENT WAYBILL NUMBER</td>
              <td className='highlight'>{shippingDocument?.clearingAgentWaybillNumber}</td>
            </tr>
            <tr>
              <td>VESSEL</td>
              <td className='highlight'>{shippingDocument?.vesselName}</td>
              <td>CONTAINERS</td>
              <td className='highlight'>{shippingDocument?.containerDescription}</td>
            </tr>
            <tr>
              <td>BL/AWL NUMBER</td>
              <td className='highlight'>{referenceNumber ? referenceNumber : ""}</td>
            </tr>
          </tbody>
        </table>
        
        <table className='template-form mt-2' style={{ tableLayout: 'fixed', width: '100%', borderCollapse: "separate", fontSize: "10px" }}>
          <thead>
            <tr>
              <th className='text-center' style={{verticalAlign: "top", width: "11.4%"}}>PO NUMBER</th>
              <th className='text-center' style={{verticalAlign: "top", width: "11.4%"}}>MATERIAL NUMBER</th>
              <th className='text-center' style={{verticalAlign: "top", width: "11.4%"}}>PO ITEM NUMBER</th>
              <th style={{verticalAlign: "top", width: "24%"}}>MATERIAL DESCRIPTION</th>
              {/* <th>PROJECT</th> */}
              <th className='text-center' style={{verticalAlign: "top", width: "10%"}}>SUPPLIED QUANTITY</th>
              <th className='text-center' style={{verticalAlign: "top", width: "10%"}}>DELIVERED QUANTITY</th>
              <th className='text-center' style={{verticalAlign: "top", width: "10%"}}>UNDELIVERED QUANTITY</th>
              <th style={{verticalAlign: "top", width: "11.4%"}}>SUPPLIER</th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrderItems?.sort((a, b) => a.purchaseOrderNumber - b.purchaseOrderNumber)?.map((row, index, arr) => (
              <>
              <tr key={index}>
                <td className='text-center'>{row.item.purchaseOrderNumber}</td>
                <td className='text-center'>{row.item.materialNumber}</td>
                <td className='text-center'>Item {row.item.purchaseOrderItemNumber}</td>
                <td>{row.item.materialDescription}</td>
                {/* <td></td> */}
                <td className='text-center'>{row.suppliedQty}</td>
                <td className='text-center'>{row.quantityDelivered}</td>
                <td className='text-center'>{row.undeliveredQuantity}</td>
                <td>{row.supplier}</td>
              </tr>
              {/* Add a blank row if the next row has a different purchaseOrderNumber */}
                {index < arr.length - 1 && row.item.purchaseOrderNumber !== arr[index + 1].item.purchaseOrderNumber && (
                  <tr key={`blank-${index}`}><td colSpan="9" className='blank-row' style={{height:"8px",backgroundColor:"#e9e9e9"}}></td></tr>
                )}
              </>
            ))}
          </tbody>
        </table>

        <h6 className='m-0 mt-2'>RECIEVED BY: 
          <span className='fw-500 blue-text'>{handOffDocument?.portOfficerId !== null && ` ${handOffDocument?.portOfficer?.firstName ?? ""} ${handOffDocument?.portOfficer?.lastName ?? ""}`}</span>
        </h6>
      </div>

      {handOffDocument?.portOfficerId === null && currentRolePage === "portofficer"
        &&
        <div style={{marginTop: "16px", padding: "0 24px", paddingTop: "16px", borderTop: "1px solid #d9d9d9"}}>
          <input onClick={event => setIsChecked(event.target.checked)} type="checkbox" />
          <small className="ml-2" style={{fontSize: "12px"}}>I, <strong className="uppercase">{user?.firstName} {user?.lastName}</strong>, acknowledge the handoff document with the above stated materials.</small>
        
          <button 
              style={{width: "100%", justifyContent: "center", marginTop:"16px"}} 
              type="submit" 
              disabled={!isChecked || isSubmitting}
              className="custom-button orange"
              onClick={() => handleHandoffDocumentAcknowledgement()}
              >{isSubmitting ? "Loading..." : "Acknowledge"}</button>
        </div>
      }
      <ToastContainer/>
    </div>
  );
};

export default PdfGenerator;
