import { makeGetRequest } from "request";

export const TotalRejectedModeOfTransportation = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalRejectedModeOfTransportation",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalApprovedModeOfTransportation = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalApprovedModeOfTransportation",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalAssignedModeOfTransportation = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalAssignedModeOfTransportation",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const TotalReviewedModeOfTransportation = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getTotalReviewedModeOfTransportation",
        params: {
            startDate: startDate,
            endDate: endDate
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.totalCount })
        .catch((error:any) => console.log(error) );
}

export const NumberOfApprovedAndRejectedModeOfTransportation = (month: string, year: number): Promise<number> => {
    var request: Record<string, any> = {
        what: "getNumberOfApprovedAndRejectedModeOfTransportation",
        params: { 
            ...(parseInt(month) > 0 && {month: month}),
            year: year,
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data })
        .catch((error:any) => console.log(error) );
}

export const CostOfApprovedModeOfTransportationSea = (month: string, year: number): Promise<number> => {
    var request: Record<string, any> = {
        what: "getCostOfApprovedModeOfTransportation",
        params: { 
            ...(parseInt(month) > 0 && {month: month}),
            year: year,
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.seaCost })
        .catch((error:any) => console.log(error) );
}

export const CostOfApprovedModeOfTransportationAir = (month: string, year: number): Promise<number> => {
    var request: Record<string, any> = {
        what: "getCostOfApprovedModeOfTransportation",
        params: { 
            ...(parseInt(month) > 0 && {month: month}),
            year: year,
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.airCost })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalApprovedModeOfTransportation = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalApprovedModeOfTransportation",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalRejectedModeOfTransportation = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalRejectedModeOfTransportation",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalAssignedModeOfTransportation = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalAssignedModeOfTransportation",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}

export const PercentageChangeOfTotalReviewModeOfTransportation = (startDate: string, endDate: string): Promise<number> => {
    var request: Record<string, any> = {
        what: "getPercentageChangeOfTotalReviewModeOfTransportation",
        params: { 
            startDate: startDate,
            endDate: endDate 
        }
    };
    
    return makeGetRequest(request)
        .then((response: any) => { return response.data.data.percentageChange })
        .catch((error:any) => console.log(error) );
}