import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal'
import { useEffect, useState } from "react";
import loading from "../../assets/images/loading.gif"
import Layout from '../Layout';
import { customStyles } from 'helpers';
import { makeGetRequest } from 'request';

const UserPermissionsManagement =  () => {
    const [isLoading, setIsLoading] = useState(false)
    const getEstimatedCostLimit = () => {
        setIsLoading(true)
        var request: Record<string, any> = {
            what: "getEstimatedCostLimit",
            params: {}
        };
        
        makeGetRequest(request)
            .then((response: any) => {
                setIsLoading(false)
                const res = response.data.data
                console.log(res)
            })
            .catch((error:any) => 
                toast.error(error.errorMessage)
            );
    }

    // const AssignUserToPermissions = () => {
    //     setIsLoading2(true)
    //     var request: Record<string, any> = {
    //         what: "",
    //         data: {}
    //     };
        
    //     makePatchRequest(request)
    //         .then((response: any) => {
    //             window.location.reload() // reload for updated permissions to apply
    //             // setIsLoading2(false)
    //             // toast.success(response.msg)
    //             // getAllUsersForARole()
    //         })
    //         .catch((error:any) => 
    //             {toast.error(error.msg); setIsLoading2(false)}
    //         );
    // }

    useEffect(() => {
        getEstimatedCostLimit();        // eslint-disable-next-line
    }, [])

    const page = "MOT Change Request Threshold"

    return (
        <Layout title={page}>
           <div className="container">
                <div className="main">
                    <h3 className="page_title">{  }</h3>     
                    <div className="main-inner" style={{height: "100vh"}}>
                        <div style={{padding: "16px 12px", fontSize: "14px", fontWeight: "500", color: "#333"}}>
                            Threshold Settings
                        </div>
                        <table>
                            <thead>
                                <th>SN</th>
                                <th>Approval Level</th>
                                <th>Freight Cost</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        DGM Tech Logistics
                                    </td>
                                    <td>
                                        <div className='d-flex' style={{width: "50%"}}>
                                            <input 
                                                id="freightCostInput" 
                                                className='custom-input'
                                                type="number"
                                                style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}} 
                                                value="2000" />
                                            <input
                                                className='custom-input'
                                                style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                                type="text" disabled value={(document.getElementById("freightCostInput") as HTMLInputElement)?.value} />
                                        </div>
                                    </td>
                                    <td>
                                        <button className='custom-button orange' style={{height: "31px"}}>Update</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>                        
                    </div>                 
                </div>
            </div>
            <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                <div className="loader">
                    <img src={loading} alt="loading" />
                    <p>Loading data...</p>
                </div>
            </Modal>
            <ToastContainer /> 
        </Layout>
    )
}

export default UserPermissionsManagement