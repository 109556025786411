import { useSelector } from "react-redux";
import { makeGetRequest } from "request";

export const useDashboardData = () => {
    const user = useSelector((state: any) => state.tepngUser.value);
    const TotalInactivePurchaseOrders = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getAllInactivePurchaseOrders",
            params: { 
                userId: user?.id,
                startDate: startDate,
                endDate: endDate,
                vendorCode: user?.vendorCode
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.totalCount })
            .catch((error:any) => console.log(error) );
    }

    const TotalPendingMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPendingMaterialReadinessDocument",
            params: { 
                userId: user?.id,
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.totalCount })
            .catch((error:any) => console.log(error) );
    }

    const TotalIssuedMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getIssuedMaterialReadinessDocument",
            params: { 
                userId: user?.id,
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.totalCount })
            .catch((error:any) => console.log(error) );
    }

    const TotalMaterialReadinessDocumentWithPOC = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getMaterialReadinessDocumentWithPOC",
            params: { 
                userId: user?.id,
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.totalCount })
            .catch((error:any) => console.log(error) );
    }

    const NumberOfIssuedPickedAndOutstandingMRD = (month: string, year: number): Promise<number> => {
        var request: Record<string, any> = {
            what: "getNumberOfIssuedPickedAndOutstandingMRD",
            params: { 
                userId: user?.id,
                ...(parseInt(month) > 0 && {month: month}),
                year: year
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data })
            .catch((error:any) => console.log(error) );
    }

    const NumberOfCompletedAndNotCompletedMRD = (month: string, year: number): Promise<number> => {
        var request: Record<string, any> = {
            what: "getNumberOfCompletedAndNotCompletedMRD",
            params: { 
                userId: user?.id,
                vendorCode: user?.vendorCode,
                ...(parseInt(month) > 0 && {month: month}),
                year: year
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data })
            .catch((error:any) => console.log(error) );
    }

    const PercentageChangeOfAllActivePurchaseOrders = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPercentageChangeOfAllActivePurchaseOrders",
            params: { 
                // userId: user?.id,
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data.percentageChange })
            .catch((error:any) => console.log(error) );
    }

    const PercentageChangeOfAllIssuedMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPercentageChangeOfAllIssuedMaterialReadinessDocument",
            params: { 
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data.percentageChange })
            .catch((error:any) => console.log(error) );
    }

    const PercentageChangeOfAllPendingMaterialReadinessDocument = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPercentageChangeOfAllPendingMaterialReadinessDocument",
            params: { 
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data.percentageChange })
            .catch((error:any) => console.log(error) );
    }

    const PercentageChangeOfTotalMaterialReadinessDocumentWithPOC = (startDate: string, endDate: string): Promise<number> => {
        var request: Record<string, any> = {
            what: "getPercentageChangeOfTotalMaterialReadinessDocumentWithPOC",
            params: { 
                startDate: startDate,
                endDate: endDate 
            }
        };
        
        return makeGetRequest(request)
            .then((response: any) => { return response.data.data.percentageChange })
            .catch((error:any) => console.log(error) );
    }

    return { 
        TotalInactivePurchaseOrders, 
        TotalPendingMaterialReadinessDocument, 
        TotalIssuedMaterialReadinessDocument, 
        TotalMaterialReadinessDocumentWithPOC,
        NumberOfIssuedPickedAndOutstandingMRD,
        NumberOfCompletedAndNotCompletedMRD,
        PercentageChangeOfAllActivePurchaseOrders,
        PercentageChangeOfAllIssuedMaterialReadinessDocument,
        PercentageChangeOfAllPendingMaterialReadinessDocument,
        PercentageChangeOfTotalMaterialReadinessDocumentWithPOC
    };
}